import { Component, AfterViewInit, ViewChild, ElementRef, OnInit  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ContactModel } from 'src/app/models/contact.model';
import { MessageModel } from 'src/app/models/message.model';
import { ContactService } from '../../user-account/services/contact.service';
import { MessageService } from '../../user-account/services/message.service';

@Component({
  selector: 'app-contact-one',
  templateUrl: './contact-one.component.html',
  styleUrls: ['./contact-one.component.scss']
})
export class ContactOneComponent implements AfterViewInit , OnInit {
  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  Contact: ContactModel = {};
  message: MessageModel = {};
  Form: FormGroup;
  map: google.maps.Map;
  lat = 40.73061;
  lng = -73.935242;
  coordinates = new google.maps.LatLng(this.lat, this.lng);
  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 8
  };
  marker = new google.maps.Marker({
    position: this.coordinates,
    map: this.map,
    title: 'Hello World!'
  });
  constructor(
    private contactService: ContactService,
     private messageService: MessageService, 
     private fb: FormBuilder, 
     public toaster: ToastrService,
     ) { }
  createForm(): FormGroup{
    return this.fb.group({
      id: [this.message.id],
      email: [this.message.email, Validators.required],
      isView: [this.message.isView],
      mobile: [this.message.mobile, Validators.required],
      name: [this.message.name, Validators.required],
      subject: [this.message.subject],
      theMessage: [this.message.theMessage],
    });
  }
  ngOnInit(): void {
   this.contactService.GetContact()
   .subscribe(data => {
     this.Contact = data;
   });
   this.message = new MessageModel();
   this.Form = this.createForm();
  }
  SendMessage(): void{
    this.message = this.Form.getRawValue();
    this.messageService.CreateMessage(this.message)
    .subscribe(data => {
      if(data === true) {
        this.toaster.success('The Message Send Successfly');
        this.message = new MessageModel();
        this.Form = this.createForm();
      }
    });
  }
  ngAfterViewInit() {
    this.mapInitializer();
  }
  mapInitializer() {
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.marker.setMap(this.map);
  }
}
