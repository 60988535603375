import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RegionModel } from './model/restion.model';
import { CityModel } from './model/city.model';

@Injectable({
  providedIn: 'root'
})
export class CityService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }

  getAll(page: number): Observable<CityModel[]> {
    return this.http
      .get<CityModel[]>(`${this.baseUrl}/City/GetAll/${page}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetResionsByCityId(cityId: number): Observable<RegionModel[]> {
    return this.http
    .get<RegionModel[]>(`${this.baseUrl}/City/GetResionsByCityId/${cityId}`, this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error.json())));
   }
}
