import { CartService } from 'src/app/pages/user-account/services/cart.service';
import { KatoraService } from 'src/app/services/katora.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WishListService } from './pages/user-account/services/wish-list.service';




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',

  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit,OnDestroy {
  title = 'katora';

  constructor(
    private translate: TranslateService,
    private wishListService: WishListService,
    private katoraService: KatoraService,
    private cartService: CartService
    ) {

    translate.addLangs(['en', 'ar']);

    if (localStorage.getItem('lang') === null || localStorage.getItem('lang') === undefined) {

      localStorage.setItem('lang', 'en');
      this.translate.use('en');
      translate.setDefaultLang('en');
    }else{
      translate.setDefaultLang(localStorage.getItem('lang'));
    }
    
  }
  doBeforeUnload(){
     
    const token = localStorage.getItem('token');
    if (token != null) {
    this.wishListService.InsertList(this.katoraService.getProductFromWishlist())
    .subscribe(result => {
      
    });
    this.cartService.InsertList(this.katoraService.getProductFromCart())
    .subscribe(result => {
      
    });
   }
  }
  ngOnDestroy(): void {
  
  }
  ngOnInit(): void {
    const token = localStorage.getItem('token');
   if (token != null) {
    this.wishListService.GetForCurrentUser(0)
    .subscribe(data => {
      if(data.length > 0){
        data.forEach(element => {
          if (element.quantity <= 0) {
            element.quantity = 1;
          }
          this.katoraService.addProductToWishlistWithoutToast(element);
        });
      }
    });
    this.cartService.GetForCurrentUser()
    .subscribe(data => {
       
      if(data.length > 0){
        data.forEach(element => {
          if (element.quantity <= 0) {
            element.quantity = 1;
          }
          this.katoraService.addProductToCartWithoutToast(element);
        });
      }
    });
  }
  }
}

