import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '../../components/components.module';
import { HeaderModule } from '../../layouts/header/header.module';
import { FooterModule } from 'src/app/layouts/footer/footer.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AllJobsComponent } from './components/all-jobs/all-jobs.component';
import { ApplyJobComponent } from './components/apply-job/apply-job.component';
import { SidebarModule } from 'src/app/layouts/sidebar/sidebar.module';
import { JopDetailsComponent } from './components/jop-details/jop-details.component';
import { ModalModule } from 'ngb-modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [
    AllJobsComponent,
    ApplyJobComponent,
    JopDetailsComponent
  ],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule,
    ComponentsModule,
    FormsModule,
    HeaderModule,
    FooterModule,
    SidebarModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers : [NgbActiveModal],
  entryComponents: [ApplyJobComponent]
})
export class ApplyJobModule { }
