import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailValidator } from 'src/app/core/utils/app-validators';
import { ContactModel } from 'src/app/models/contact.model';
import { MessageModel } from 'src/app/models/message.model';
import { ContactService } from 'src/app/pages/user-account/services/contact.service';
import { MessageService } from 'src/app/pages/user-account/services/message.service';

@Component({
  selector: 'app-footer-three',
  templateUrl: './footer-three.component.html',
  styleUrls: ['./footer-three.component.scss']
})
export class FooterThreeComponent implements OnInit {
  contact: ContactModel = {};
  message: MessageModel = {};
  Form: FormGroup;
  current_language = 'ar';
  constructor(private contactService: ContactService, private messageService: MessageService, private fb: FormBuilder) { 
    this.current_language = localStorage.getItem('lang');
  }
  date=new Date().getFullYear();
  ngOnInit() {
    this.contactService.GetContact()
    .subscribe(data => {
      this.contact = data;
    });
    this.message = new MessageModel();
    this.Form = this.createForm();
  }
  createForm(): FormGroup {
    return this.fb.group({
      id: [this.message.id],
      email: [this.message.email,emailValidator],
      isView: [this.message.isView],
      mobile: [this.message.mobile],
      name: [this.message.name],
      subject: [this.message.subject],
      theMessage: [this.message.theMessage],
    });
  }
  onSubmit() {
    this.message = this.Form.getRawValue();
    this.messageService.CreateMessage(this.message)
    .subscribe(data => {
      if ( data === true ) {
        this.message = new MessageModel();
        this.Form = this.createForm();
      }
    });
  }

}
