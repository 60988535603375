import { Component, OnInit } from '@angular/core';
import { KatoraService } from 'src/app/services/katora.service';
import { Lightbox } from 'ngx-lightbox';
import { ProductModel } from '../../shop/models/Product.model';
import { ProductService } from '../../shop/product.service';
import { ActivatedRoute } from '@angular/router';
import { ProductWithDetailsModel } from '../../shop/models/product-with-details.model';
import { CartModel } from '../../user-account/models/Cart.model';
import { SessionService } from '../../session/session.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { SlectCityComponent } from 'src/app/components/slect-city/slect-city.component';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-product-one',
  templateUrl: './product-one.component.html',
  styleUrls: ['./product-one.component.scss']
})
export class ProductOneComponent implements OnInit {

  public productAddedToCart;
  public productTotal;
  cartModel: CartModel = {};
  wishList: any = {};
  Id = 0;
  unitPrice = 0;
  productPrice = 0;
  product: ProductWithDetailsModel = {};
  products: ProductModel[] = [];
  public current_language;
  myThumbnail="https://wittlock.github.io/ngx-image-zoom/assets/thumb.jpg";
  myFullresImage="https://wittlock.github.io/ngx-image-zoom/assets/fullres.jpg";
  slideProductConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    asNavFor: '.thumbs',
  };

  slideProductNavConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".feedback",
    //"asNavFor": "slideProductConfig",
    centerMode: true,
    centerPadding: '0px',
    dots: true,
    arrows: false,
    focusOnSelect: true
  };

  slideRelatedProductConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    infinite: false,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };
  constructor(
    private katoraService: KatoraService,
    private _lightbox: Lightbox,
    private productService: ProductService,
    private route: ActivatedRoute,
    private sessionService:SessionService,
    private matDialog : MatDialog,
    private toastr: ToastrService,
    ) {
    // this.loadAlbum();
    this.current_language = localStorage.getItem('lang');
    this.Id = + route.snapshot.paramMap.get('Id');
  }

  private _album = [];
  open(index: number): void {

    // open lightbox
    this._lightbox.open(this._album, index);
  }
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  onAddWishlist(product: any) {
    this.wishList.id = this.product.id;
    this.wishList.productId = this.product.id;
    this.wishList.quantity = this.product.amount;
    var imageindex = this.product.productImages.findIndex(x=>x.isMain == true);
    if(imageindex != -1){
      this.wishList.imageUrl = this.product.productImages[imageindex].imageUrl;
    }
    this.wishList.productNameEn = this.product.nameEn;
    this.wishList.productNameAr = this.product.nameAr;
    this.wishList.totalPrice = this.product.price;
    this.wishList.descriptionEn = this.product.smallDescriptionEn;
    this.wishList.descriptionAr = this.product.smallDescriptionAr;
    this.katoraService.addProductToWishlist(this.wishList);
  }
  onAddCart(product: any) {
    debugger;
    if(this.sessionService.loggedIn){
      this.productService.ChickGqantity(product.id,product.amount,0)
      .subscribe(result=>{
        if(result == true){
          this.cartModel.id = this.product.id;
          var imageindex = this.product.productImages.findIndex(x=>x.isMain == true);
          if(imageindex != -1){
            this.cartModel.imageUrl = this.product.productImages[imageindex].imageUrl;
          }
          this.cartModel.productId = this.product.id;
          this.cartModel.quantity = this.product.amount;
          this.cartModel.totalPrice = this.product.price;
          this.cartModel.quantity = 1;
          this.cartModel.productUnits = this.product.productUnits;
          this.katoraService.addProductToCart(this.cartModel);
        }
        else if(result == false){
          this.toastr.success("This product is not available in this branch");

        }
        else{
          window.location.reload();
        }
      },error=>{
        window.location.reload();

      })
    }
    else{
      var cityId= localStorage.getItem('CityId')
      if(cityId == null || cityId == undefined || cityId == ''){
        let dialogRef : MatDialogRef<SlectCityComponent>;
        dialogRef =this.matDialog.open(SlectCityComponent,{ disableClose: true });
        dialogRef.afterClosed()
        .subscribe(x=>{
          cityId = x.cityId;
          this.productService.ChickGqantity(product.id,product.amount,+cityId)
          .subscribe(result=>{
            if(result == true){
               localStorage.setItem('CityId',cityId.toString());
              this.cartModel.id = this.product.id;
              var imageindex = this.product.productImages.findIndex(x=>x.isMain == true);
              if(imageindex != -1){
                this.cartModel.imageUrl = this.product.productImages[imageindex].imageUrl;
              }
              this.cartModel.productId = this.product.id;
              this.cartModel.quantity = this.product.amount;
              this.cartModel.totalPrice = this.product.price;
              this.cartModel.quantity = 1;
              this.cartModel.productUnits = this.product.productUnits;
              this.katoraService.addProductToCart(this.cartModel);
            }
            else if(result == false){
              this.toastr.error("This product is not available in this branch");

            }
            else{
              window.location.reload();
            }
          },error=>{
            window.location.reload();
    
          })   
        })

      }
    else{
      this.productService.ChickGqantity(product.id,product.amount,+cityId)
      .subscribe(result=>{
        if(result == true){
          this.cartModel.id = this.product.id;
          var imageindex = this.product.productImages.findIndex(x=>x.isMain == true);
          if(imageindex != -1){
            this.cartModel.imageUrl = this.product.productImages[imageindex].imageUrl;
          }
          this.cartModel.productId = this.product.id;
          this.cartModel.quantity = this.product.amount;
          this.cartModel.totalPrice = this.product.price;
          this.cartModel.quantity = 1;
          this.cartModel.productUnits = this.product.productUnits;
          this.katoraService.addProductToCart(this.cartModel);
        }
        else if(result == false){
          this.toastr.error("This product is not available in this branch");

        }
        else{
          window.location.reload();
        }
      },error=>{
        window.location.reload();

      }) 
    }
   
  }

  }
  addQuantity(product) {
    this.product.amount ++;
  }
  removeQuantity(product: any) {
    if (this.product.amount > 0) {
      this.product.amount --;
    }
  }
  ngOnInit() {
    this.productService.GetById(this.Id)
    .subscribe(data => {
      this.product = data;
      this.productPrice = this.product.price;
      this.product.amount = 1;
      if(this.sessionService.loggedIn == true){
        this.productService.GetProductsForUser(this.product.categoryId, 1)
        .subscribe(prodects => {
          this.products = prodects;
        })
      }
      else{
        var CityId = localStorage.getItem('CityId')
        this.productService.GetProductsForUser(this.product.categoryId, 1)
        .subscribe(prodects => {
          this.products = prodects;
        })
      }
    })
  }
  ngAfterContentChecked() {
    this.productAddedToCart = this.katoraService.getProductFromCart();
    this.productTotal = this.katoraService.calculateTotal().toFixed(2);
  }
 

  slickInit(e) {
  }
}
