import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CategoryModel } from '../pages/shop/models/category.model';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem('token');
   httpOptions = {
    headers: new HttpHeaders({
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }

  getAll(page: number): Observable<CategoryModel[]> {
    return this.http
      .get<CategoryModel[]>(`${this.baseUrl}/Category/GetAll/${page}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetAllForHome(): Observable<CategoryModel[]> {
    return this.http
      .get<CategoryModel[]>(`${this.baseUrl}/Category/GetAllForHome`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetAllForDropdownMenu(): Observable<CategoryModel[]> {
    return this.http
      .get<CategoryModel[]>(`${this.baseUrl}/Category/GetAllForDropdownMenu`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  
  GetCount(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/Category/GetCount`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  GetById(id: number): Observable<CategoryModel> {
      return this.http
      .get<CategoryModel>(`${this.baseUrl}/Category/GetById/${id}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
     }
 
}
