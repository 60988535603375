import { CorporateGiftProductsModel } from "./corporate-gift-products.model";

export class CorporateGiftModel{
    id?: number;
    companyName?: string;
    name?: string;
    phone?: string;
    mobile?: string;
    email?: string;
    description?: string;
    isView?: boolean;
    createDate?:Date;
    products ?: CorporateGiftProductsModel[];
    constructor(item?:CorporateGiftModel){
        item = item ? item : {};
        this.id = item.id ? item.id : 0;
        this.companyName = item.companyName ? item.companyName : '';
        this.name = item.name ? item.name : '';
        this.phone = item.phone ? item.phone : '';
        this.mobile = item.mobile ? item.mobile : '';
        this.email = item.email ? item.email : '';
        this.description = item.description ? item.description : '';
        this.isView = item.isView ? item.isView : false;
        this.createDate = item.createDate ? item.createDate : null;
        this.products = item.products ? item.products : [];
    }
}