export class PaymentModel{
    cardName?: string;
    cardNumber?: string;
    expirationMonth?: string;
    expirationYear?: string;
    cardType?: string;
    securityCode?: string;
    constructor (payment ?: PaymentModel){
        payment = payment ? payment : {};
        this.cardName = payment.cardName ? payment.cardName : '';
        this.cardNumber = payment.cardNumber ? payment.cardNumber : '';
        this.expirationMonth = payment.expirationMonth ? payment.expirationMonth : '';
        this.expirationYear = payment.expirationYear ? payment.expirationYear : '';
        this.cardType = payment.cardType ? payment.cardType : '';
        this.securityCode = payment.securityCode ? payment.securityCode : '';
    }

}