import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Slider2PhotoModel } from '../models/slider2-photo.model';
@Injectable({
  providedIn: 'root'
})
export class Slider2PhotoService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }

  GetPhoto(): Observable<Slider2PhotoModel> {
    return this.http
      .get<Slider2PhotoModel>(`${this.baseUrl}/Slider2Photo/GetPhoto`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  put(payload: Slider2PhotoModel): Observable<boolean> {
    return this.http
      .post<boolean>(`${this.baseUrl}/Slider2Photo/Update`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
}
