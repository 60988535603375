import { BrandsModel } from './brands.model';
import { ExchangeDetailsModel } from './exchange-details.model';
import { GiftAmountModel } from './gift-amount.model';
import { GiftImagesModel } from './gift-images.model';
export class GiftCardModel{
    id?: number;
    nameEn          ?: string;
    nameAr          ?: string;
    nameFr          ?: string;
    descriptionEn   ?: string;
    descriptionAr   ?: string;
    descriptionFr   ?: string;
    categoryName    ?: string;
    imageUrl    ?: string;
    categoryId      ?: number;
    images          ?: GiftImagesModel[];
    exchangeDetails ?: ExchangeDetailsModel[];
    brands          ?: BrandsModel[];
    giftAmount ?: GiftAmountModel[];
    constructor(gift ?: GiftCardModel){
        gift = gift ? gift : {};
        this.id = gift.id ? gift.id : 0;
        this. nameEn           = gift. nameEn           ? gift. nameEn           : '' ;
        this. imageUrl           = gift. imageUrl           ? gift. imageUrl           : '' ;
        this. nameAr           = gift. nameAr           ? gift. nameAr           : '' ;
        this. nameFr           = gift. nameFr           ? gift. nameFr           : '' ;
        this. descriptionEn    = gift. descriptionEn    ? gift. descriptionEn    : '' ;
        this. descriptionAr    = gift. descriptionAr    ? gift. descriptionAr    : '' ;
        this. descriptionFr    = gift. descriptionFr    ? gift. descriptionFr    : '' ;
        this. categoryName     = gift. categoryName     ? gift. categoryName     : '' ;
        this. categoryId       = gift. categoryId       ? gift. categoryId       : 0 ;
        this. images           = gift. images           ? gift. images           : [] ;
        this. exchangeDetails  = gift. exchangeDetails  ? gift. exchangeDetails  : [] ;
        this. brands           = gift. brands           ? gift. brands           : [] ;
        this.giftAmount = gift.giftAmount ? gift.giftAmount: [] ;
    }
}