import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SliderInfoModel } from './models/slider-info.model';

@Injectable({
  providedIn: 'root'
})
export class SliderInfoService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem('token');
   httpOptions = {
    headers: new HttpHeaders({
       'Authorization': 'Bearer' + this.token,
       'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) {

  }
  getAll(page: number): Observable<SliderInfoModel[]> {
    return this.http
      .get<SliderInfoModel[]>(`${this.baseUrl}/SliderInfo/GetAll/${page}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCount(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/SliderInfo/GetCount`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  createSliderInfo(payload: SliderInfoModel): Observable<boolean> {
    return this.http
      .post<boolean>(`${this.baseUrl}/SliderInfo/Insert`, payload, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  put(payload: SliderInfoModel): Observable<boolean> {
    return this.http
      .put<boolean>(`${this.baseUrl}/SliderInfo/Update`, payload, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  delete(payload: SliderInfoModel): Observable<boolean> {
    return this.http
      .delete<boolean>(`${this.baseUrl}/SliderInfo/Delete/${payload.id}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetById(id: number): Observable<SliderInfoModel> {
      return this.http
      .get<SliderInfoModel>(`${this.baseUrl}/SliderInfo/GetById/${id}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
     }
}
