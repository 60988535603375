import { Component } from '@angular/core';

@Component({
    selector: 'gift-cards-two',
    templateUrl: 'gift-cards-two.component.html',
    styleUrls: ['gift-cards-two.component.scss']
})
export class GiftCardsTwoComponent {

}
