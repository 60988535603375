import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UserRegistration } from 'src/app/pages/session/model/user-registration';
import { CategoryModel } from 'src/app/pages/shop/models/category.model';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnInit {
  isIn = false;
  name:string;
  user: UserRegistration = {};
  categories: CategoryModel [] = [];
  searchName = '';
  CategoryId = 0;
  Form: FormGroup;
  constructor(
    private categoryService: CategoryService,
    private router: Router,
    private fb: FormBuilder
    ) {  }

  createForm(): FormGroup {
    return this.fb.group({
      categoryId: [this.CategoryId],
      searchName: [this.searchName]
    });
  }
  ngOnInit() {
    this.Form = this.createForm();
    this.categoryService.GetAllForDropdownMenu()
    .subscribe(data=>{
      this.categories = data;
      this.categories.forEach(element => {
        if(element.hideFromDropdownMenu == true){
          var i = this.categories.findIndex(x=>x.id == element.id);
          if(i > -1){
            this.categories.splice(i,1);
          }
        }
      });
    });
    const token  = localStorage.getItem('token');
    if (token != null && token != '') {
      const user = JSON.parse(window.atob(token.split('.')[1]));
      this.isIn = true;
      this.name = user.UserName
    }
    else{
      this.name = '';
    }
  }
  search() {
    var item = this.Form.getRawValue();
    this.router.navigate([`shop-search/${item.categoryId}/1/${item.searchName}`]);
  }

}
