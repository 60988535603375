import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { FooterModule } from 'src/app/layouts/footer/footer.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxImageZoomModule  } from 'ngx-image-zoom';
import { LightboxModule } from 'ngx-lightbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'src/app/components/components.module';
import { HeaderModule } from 'src/app/layouts/header/header.module';
import { MatRadioModule, MatTabsModule } from '@angular/material';
import { GiftInvoiceComponent } from './gift-invoice.component';
import { QRCodeModule } from 'angular2-qrcode';
import { BrowserModule } from '@angular/platform-browser';




@NgModule({
  declarations: [GiftInvoiceComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule,
    // NgSelectModule,
    ComponentsModule,
    HeaderModule,
    FooterModule,
    QRCodeModule,
    FormsModule,
    BrowserModule
  ],
  exports:[GiftInvoiceComponent],
  providers: [
    DatePipe,
  ]
})
export class GiftInvoiceModule { }
