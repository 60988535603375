export class UserModel{
  id?: number ;
  firstName?: string ;
  lastName?: string ;
  gender?: string ;
  email?: string ;
  phone?: string ;
  password?: string ;
  confirmPassword?: string ;
  address?: string ;
  userId?: string ;
  code?: number ;
  cityId?: number;
  reagionId?: number;
  isSaudi ?: boolean;
  constructor(user?: UserModel){
      user = user ? user : {};
      this.id = user.id ? user.id : 0 ;
      this.isSaudi = user.isSaudi ? user.isSaudi : false ;
      this.cityId = user.cityId ? user.cityId : 0 ;
      this.reagionId = user.reagionId ? user.reagionId : 0 ;
      this.code = user.code ? user.code : 0 ;
      this.firstName = user.firstName ? user.firstName : '';
      this.lastName = user.lastName ? user.lastName : '';
      this.gender = user.gender ? user.gender : '';
      this.email = user.email ? user.email : '';
      this.password = user.password ? user.password : '';
      this.phone = user.phone ? user.phone : '';
      this.confirmPassword = user.confirmPassword ? user.confirmPassword : '';
      this.address = user.address ? user.address : '';
      this.userId = user.userId ? user.userId : '';
  }
}