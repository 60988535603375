import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BoxCartModel } from 'src/app/pages/user-account/models/box-cart.model';
import { CartModel } from 'src/app/pages/user-account/models/Cart.model';
import { CartService } from 'src/app/pages/user-account/services/cart.service';

import { KatoraService } from '../../services/katora.service';

@Component({
  selector: 'app-cart-dropdown',
  templateUrl: './cart-dropdown.component.html',
  styleUrls: ['./cart-dropdown.component.scss']
})
export class CartDropdownComponent implements OnInit {
  public productAddedToCart: CartModel[] = [];
  public boxesAddedToCart: BoxCartModel[] = [];
  public productTotal = 0;
  public cartCount = 0;
  openForm = false;
  isLogin = false;
  public current_language;

  constructor(
    public translate: TranslateService,
    private katoraService: KatoraService, private cartService: CartService , public toaster: ToastrService) {
    this.current_language = localStorage.getItem('lang');
  }

  ngOnInit() {
    this.productAddedToCart = this.katoraService.getProductFromCart();  
    this.boxesAddedToCart = this.katoraService.getboxesFromCart();  
    const token = localStorage.getItem('token');
    if(token != null){
      this.isLogin = true;
    }

  }
  ngAfterContentChecked(){
    this.productAddedToCart = this.katoraService.getProductFromCart();    
    this.boxesAddedToCart = this.katoraService.getboxesFromCart();  
    this.productTotal = + this.katoraService.calculateTotal().toFixed(2);
    this.cartCount = this.katoraService.getCartCount();

  }
  removeProduct(product: any){
    this.katoraService.removeProductFromCart(product);
    this.cartService.delete(product)
    .subscribe(data =>{

    });
    
  }
  removeBoxFromCart(product: any){
    //debugger;
    const i = this.boxesAddedToCart.indexOf(product);
    this.katoraService.removeBoxFromCart(this.boxesAddedToCart[i]);
    this.cartService.delete(this.boxesAddedToCart[i])
  .subscribe(data =>{
    this.ngOnInit();
  });
  }
  // ngOnInit() {
  //   const token = localStorage.getItem('token');
  //   this.cartCount = + localStorage.getItem('cartCount');
  //   this.productAddedToCart = [];
  //   this.productAddedToCart = JSON.parse(localStorage.getItem('cartProducts'));
  //   this.productTotal = 0;
  //   if (this.productAddedToCart !== null) {
  //     this.productAddedToCart.forEach(element => {
  //       this.productTotal += element.totalPrice * element.quantity;
  //     });
  //   }
  //   // if (token != null) {
  //   //   this.cartService.GetCount()
  //   //   .subscribe(count => {
  //   //     this.cartCount = count;
  //   //   });
  //   //   this.cartService.GetForCurrentUser()
  //   //   .subscribe(data => {
  //   //     this.productAddedToCart = data;
  //   //     this.openForm = true;
  //   //     console.log(this.productAddedToCart);
  //   //     this.productAddedToCart.forEach(element => {
  //   //       this.productTotal += element.productPrice * element.quantity;
  //   //     });
  //   //   });
  //   // }
  // }
  // ngAfterContentChecked() {
  //   const token = localStorage.getItem('token');
  //   if (token != null) {
  //     this.cartCount = + localStorage.getItem('cartCount');
  //     this.productAddedToCart = [];
  //     this.productAddedToCart = JSON.parse(localStorage.getItem('cartProducts'));
  //     this.productTotal = 0;
  //     if (this.productAddedToCart !== null) {
  //       this.productAddedToCart.forEach(element => {
  //         this.productTotal += (element.totalPrice * element.quantity);
  //       });
  //     }
  //     // this.cartService.GetCount()
  //     // .subscribe(count => {
  //     //   this.cartCount = count;
  //     // });
  //     // this.cartService.GetForCurrentUser()
  //     // .subscribe(data => {
  //     //   this.productAddedToCart = data;
  //     //   this.openForm = true;
  //     //   console.log(this.productAddedToCart);
  //     //  
  //     // });
  //   }
  // }
  // removeProduct(product: any) {
  //   this.cartService.removeProductFromCart(product);
 
  //   const i = this.productAddedToCart.findIndex(x => x.id == product.id);
  //   if (i != -1) {
  //     this.productAddedToCart.splice(i, 1);
  //     this.cartCount --;
  //     localStorage.setItem('cartCount' , this.cartCount.toString());
  //     this.toaster.success('Delete Done');
  //   }
  //   this.cartService.delete(product)
  //    .subscribe(data => {
  //     if (data === true) {
  //     }
  //   });
  //   // this.katoraService.removeProductFromCart(product);
  // }
}
