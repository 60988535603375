import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GivingGiftModel } from './models/giving-gift.model';
import { GiftMessageDesignMode } from './models/gift-message-design.model';
import { EmailModel } from './models/email.model';
@Injectable({
  providedIn: 'root'
})
export class GivingGiftService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }
  createGivingGiftCard(payload: GivingGiftModel): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/GivingGift/Insert`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  UpdateAfterPay(payload: GivingGiftModel): Observable<boolean> {
    return this.http
      .post<boolean>(`${this.baseUrl}/GivingGift/UpdateAfterPay`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  
  sendEmail(payload: EmailModel){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.http.post('https://formspree.io/f/mvodqlnj',
      { name: "", replyto: payload.email, message: payload.message },
      { 'headers': headers }).subscribe(
        response => {
        }
      );
  }
  GetById(id: number): Observable<GiftMessageDesignMode> {
    return this.http
    .get<GiftMessageDesignMode>(`${this.baseUrl}/GivingGift/GetById/${id}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error.json())));
   }
   GetByIdForInvoice(id: number): Observable<GivingGiftModel> {
    return this.http
    .get<GivingGiftModel>(`${this.baseUrl}/GivingGift/GetByIdForInvoice/${id}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error.json())));
   }
   
   GetForCurrentUser(): Observable<GiftMessageDesignMode[]> {
      
    return this.http
    .get<GiftMessageDesignMode[]>(`${this.baseUrl}/GivingGift/GetForCurrentUser`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error.json())));
   }
   Apply(id: number): Observable<string> {
    return this.http
      .get<string>(`${this.baseUrl}/GivingGift/ApplyGift/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

}
