import { GivingGiftModule } from './pages/gift-cards/components/giving-gift/giving-gift.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxSpinnerModule } from "ngx-spinner";


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
};


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { ComponentsModule } from './components/components.module';
import { HeaderModule } from './layouts/header/header.module';
import { FooterModule } from './layouts/footer/footer.module';
import { SidebarModule } from './layouts/sidebar/sidebar.module';

import { HomeModule } from './pages/home/home.module';

import { ShopModule } from './pages/shop/shop.module';
import { ProductSingleModule } from './pages/product-single/product-single.module';
import { CartModule } from './pages/cart/cart.module';
import { UserAccountModule } from './pages/user-account/user-account.module';
import { BlogModule } from './pages/blog/blog.module';

import { SessionModule } from './pages/session/session.module';


import { NotfoundComponent } from './pages/notfound/notfound.component';

import { AboutModule } from './pages/about/about.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



import { ToastrModule } from 'ngx-toastr';

import { LightboxModule } from 'ngx-lightbox';
import { NgxImageZoomModule  } from 'ngx-image-zoom';
import { StoreLocationsModule } from './pages/store-locations/store-locations.module';
import { CreatePurchaseCouponComponent } from './pages/user-account/purchase-coupons/create-purchase-coupon/create-purchase-coupon.component';
import { CheckoutModule } from './pages/checkout/chickout.module';
import { ApplyJobModule } from './pages/apply-job/apply-job.module';
import { GiftCardModule } from './pages/gift-cards/gift-card.module';
import { ViewGiftCardModule } from './pages/gift-cards/components/view-gift-card/view-gift-card.module';
import { GiftForUserModule } from './pages/gift-cards/components/gift-for-user/gift-for-user.module';
import { InvoiceModule } from './pages/checkout/invoice/invoice.module';
import { AngularTawkComponent } from 'angular-tawk';
import { ErrorOnPayModule } from './pages/checkout/error-on-pay/error-on-pay.module';
import { CheckoutAsAGuestModule } from './pages/checkout/checkout-as-a-guest/checkout-as-a-guest.module';
import { CorporateGiftModule } from './pages/corporate-gifts/corporate-gifts.module';
import { BoxesModule } from './pages/boxes/boxes.module';
import { GivingGiftTwoModule } from './pages/gift-cards/components/giving-gift-two/giving-gift-two.module';
import { GiftInvoiceModule } from './pages/gift-cards/components/gift-invoice/gift-invoice.module';
import { GivingGiftTwoEnModule } from './pages/gift-cards/components/giving-gift-two-en/giving-gift-two-en.module';


@NgModule({
  declarations: [
    AppComponent,
    // CheckoutComponent,
    NotfoundComponent,
    AngularTawkComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot(), // ToastrModule added
    NgxImageZoomModule.forRoot(),
    NgxSpinnerModule,
    LightboxModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ComponentsModule,
    HeaderModule,
    FooterModule,
    SidebarModule,
    ApplyJobModule,
    BoxesModule,
    HomeModule,
    ShopModule,
    ProductSingleModule,
    CartModule,
    UserAccountModule,
    BlogModule,
    CheckoutModule,
    InvoiceModule,
    SessionModule,
    AboutModule,
    CorporateGiftModule,
    StoreLocationsModule,
    GiftCardModule,
    GivingGiftTwoModule,
    GivingGiftTwoEnModule,
    GivingGiftModule,
    ViewGiftCardModule,
    GiftForUserModule,
    ErrorOnPayModule,
    CheckoutAsAGuestModule,
    GiftInvoiceModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  exports: [NotfoundComponent,AngularTawkComponent],
  entryComponents: [CreatePurchaseCouponComponent]
})
export class AppModule { 
}
