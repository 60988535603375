import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CityService } from '../../session/city.service';
import { CityModel } from '../../session/model/city.model';
import { RegionModel } from '../../session/model/restion.model';
import { ShoppingAddressModel } from '../models/shopping-address.model';
import { ShoppingAddressService } from '../services/shopping-address.service';

@Component({
  selector: 'app-shipping-address',
  templateUrl: './shipping-address.component.html',
  styleUrls: ['./shipping-address.component.scss']
})
export class ShippingAddressComponent implements OnInit {
  public current_language;
  shoppingAddresses: ShoppingAddressModel[] = [];
  Form: FormGroup;
  address: ShoppingAddressModel = {};
  cities: CityModel[] = [];
  resions: RegionModel[] = [];
  openForm = false;
  type = 'add';
  constructor(
    private shoppingAddressService: ShoppingAddressService,
    private fb: FormBuilder,
    public toaster: ToastrService,
    private cityService: CityService
  ) { 
    this.current_language = localStorage.getItem('lang');
  }
    createForm(): FormGroup {
      this.openForm = true;
      return this.fb.group({
        id : [this.address.id],
        userName    : [this.address.userName   ],
        userId      : [this.address.userId     ],
        firstName   : [this.address.firstName  ],
        lastName    : [this.address.lastName   ],
        companyName : [this.address.companyName],
        address     : [this.address.address    ],
        city        : [this.address.city       ],
        postalCode  : [this.address.postalCode ],
        phone       : [this.address.phone      ],
        resionName  : [this.address.resionName ],
        resionId    : [this.address.resionId   ],
        cityId      : [this.address.cityId     ],
        isMain      : [this.address.isMain     ],
      });
    }
  ngOnInit() {
    this.shoppingAddressService.GetForCurrentUser()
    .subscribe(data => {
      this.shoppingAddresses = data;
    });
    this.cityService.getAll(0)
    .subscribe(data => {
      this.cities = data;
      this.address = new ShoppingAddressModel();
      this.type = 'add';
      this.Form = this.createForm();
    });
  }
  getResions() {
    this.address = this.Form.getRawValue();
    this.cityService.GetResionsByCityId(this.address.cityId)
    .subscribe(resions => {
      this.resions = resions;
    });
  }
  submit() {
    this.address = this.Form.getRawValue();
    if(this.type === 'add'){
      this.shoppingAddressService.createShoppingAddress(this.address)
    .subscribe(data => {
      if(data === true) {
        this.toaster.success('Add Done Success');
        // this.setDefualt(this.address);
        this.ngOnInit();
      }
    });
    }
    else{
      this.shoppingAddressService.put(this.address)
      .subscribe(data => {
        if ( data === true ) {
          this.toaster.success('Edit Done Success');
          this.ngOnInit();
        }
      });
    }
  }
  edit(item: ShoppingAddressModel) {
    this.type = 'edit';
    this.address = item;
    this.cityService.GetResionsByCityId(this.address.cityId)
    .subscribe(resions => {
      this.resions = resions;
    })
    this.Form = this.createForm();
  }
  delete(item: ShoppingAddressModel){
    this.shoppingAddressService.delete(item)
    .subscribe(result => {
      if(result === true){
        this.toaster.success('Delete Done Success');
        const i = this.shoppingAddresses.indexOf(item);
        if (i !== -1) {
          this.shoppingAddresses.splice(i , 1);
        }
      }
    })
  }
  setDefualt(item: ShoppingAddressModel) {
    this.shoppingAddressService.setDefualt(item)
    .subscribe(data => {
      if(data == true){
        if(this.current_language == 'ar'){
          this.toaster.success('تم اختيار العنوان الافتراضي بنجاح')
        } 
        else{
          this.toaster.success('Default Address ');
        }
      }
      else{
        if(this.current_language == 'ar'){
          this.toaster.error('برجاء المحاولة في مرة اخري')
        } 
        else{
          this.toaster.error('Please Try again');
        }
      }
    },error =>{
      if(this.current_language == 'ar'){
        this.toaster.error('برجاء المحاولة في مرة اخري')
      } 
      else{
        this.toaster.error('Please Try again');
      }
    });
  }
}
