import { BoxModel } from './../../models/box.model';
import { Component, OnInit } from '@angular/core';
import { BoxService } from '../../box.service';

@Component({
    selector: 'all-boxes',
    templateUrl: 'all-boxes.component.html',
    styleUrls: ['all-boxes.component.scss']
})
export class AllBoxesComponent implements OnInit {
    current_language = 'ar';

    constructor(private boxService:BoxService){
    this.current_language = localStorage.getItem('lang');

    }
    boxes:BoxModel[]=[];
    ngOnInit(): void {
        this.boxService.getAll(0)
        .subscribe(data => {
            this.boxes = data;
        })
    }

}
