import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PurchaseCouponModel } from '../models/purchase-coupon.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseCouponService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem('token');
   httpOptions = {
    headers: new HttpHeaders({
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {
  }
  GetForCurrentUser(): Observable<PurchaseCouponModel[]> {
    return this.http
      .get<PurchaseCouponModel[]>(`${this.baseUrl}/PurchaseCoupon/GetForCurrentUser`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCount(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/PurchaseCoupon/GetCount`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  createPurchaseCoupon(payload: PurchaseCouponModel): Observable<boolean> {
        return this.http
          .post<boolean>(`${this.baseUrl}/PurchaseCoupon/Insert`, payload, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
  GetByCode(code: string): Observable<PurchaseCouponModel> {
        return this.http
          .get<PurchaseCouponModel>(`${this.baseUrl}/PurchaseCoupon/GetByCode/${code}`, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
 put(payload: PurchaseCouponModel): Observable<boolean> {
        return this.http
          .put<boolean>(`${this.baseUrl}/PurchaseCoupon/Update`, payload, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
 delete(payload: PurchaseCouponModel): Observable<boolean> {
        return this.http
          .delete<boolean>(`${this.baseUrl}/PurchaseCoupon/Delete/${payload.id}`, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
 used(payload: PurchaseCouponModel): Observable<boolean> {
        return this.http
          .get<boolean>(`${this.baseUrl}/PurchaseCoupon/Used/${payload.id}`, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
}
