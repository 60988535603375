import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StoreLocationModel } from '../models/store-location.model';

@Injectable({
  providedIn: 'root'
})
export class StoreLocationService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem('token');
   httpOptions = {
    headers: new HttpHeaders({
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }

  getAll(page: number): Observable<StoreLocationModel[]> {
    return this.http
      .get<StoreLocationModel[]>(`${this.baseUrl}/StoreLocation/GetAll/${page}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
}
