import { GiftCardsComponent } from './components/gift-cards/gift-cards.component';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '../../components/components.module';
import { HeaderModule } from '../../layouts/header/header.module';
import { FooterModule } from 'src/app/layouts/footer/footer.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxImageZoomModule  } from 'ngx-image-zoom';
import { LightboxModule } from 'ngx-lightbox';
import { FormsModule } from '@angular/forms';
import { GiftCardsTwoComponent } from './components/gift-cards-two/gift-cards-two.component';




@NgModule({
  declarations: [GiftCardsComponent,GiftCardsTwoComponent],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgxImageZoomModule.forRoot(),
    LightboxModule,
    AppRoutingModule,
    TranslateModule,
    ComponentsModule,
    FormsModule,
    // Specify your library as an import
    SlickCarouselModule,
    
    HeaderModule,
    FooterModule

  ],
  exports:[GiftCardsComponent],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class GiftCardModule { }
