import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PageModel } from 'src/app/pages/global-models/page.model';
import { JobService } from '../../job.service';
import { JobModel } from '../../models/job.model';

@Component({
    // moduleId: module.id,
    selector: 'all-jobs',
    templateUrl: 'all-jobs.component.html',
    styleUrls: ['all-jobs.component.scss']
})
export class AllJobsComponent implements OnInit{
    page: PageModel = {};
     pages: number [] = [] ;
     currenPage=1;
     isLogin = false;
    constructor(
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private router: Router,
        private jobService: JobService,
    ){
      const token = localStorage.getItem('token');
      if(token != null){
        this.isLogin = true;
      }
    }
    searchText = '';
    Jobs: JobModel[] = [];
    ngOnInit(): void {
        //debugger;
        this.jobService.getAll(0)
        .subscribe(data => {
            this.Jobs = data;
            this.page.count = this.Jobs.length;
            let length =  this.page.count/ 8;
        if(length % 8){
          length ++;
        }
        for (let index = 1; index <=  length ; index ++) {
          this.pages.push(index);
         }
            console.log('this.Jobs',this.Jobs)
        });
    
    }
    // openModal():void {
    //  
    //     const modalRef=this.modalService.open(CreateJobComponent,{size:'lg'});
    //     modalRef.componentInstance.data={type:'Add New Job' , Job : new JobModel()};
    //       modalRef.result.then(
    //             (Acc:JobModel)=>{
    //               this.jobService.createJob(Acc)
    //                 .subscribe(data=>{
    //                     if(data === true){
    //                         this.toastr.successToastr('Add Done Success');
    //                         this.ngOnInit();
    //                     }
    //                     else{
    //                         this.toastr.errorToastr("Can't Add Success")
    //                     }
    //                 },
    //                   error=>{
    //                     this.toastr.errorToastr("Can't Add Success")
    //                   }
    //                 );
    //             },
    //           reason=>{
    //             this.toastr.errorToastr("Can't Add Success")
    //           }
    //       ) ;
    //   }
    datatablePageData(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
      this.jobService.getAll(pageInfo.offset + 1)
      .subscribe(data => {
          this.Jobs = data;
      });
  }
  filteredArray: any[] = []
  onPaginateChange(data) {
     
    if(data.pageIndex > data.previousPageIndex){
      this.filteredArray = this.Jobs.slice((data.previousPageIndex * data.pageSize), (data.pageIndex * data.pageSize));
    }
    else{
      this.filteredArray = this.Jobs.slice((data.pageIndex * data.pageSize), (data.previousPageIndex * data.pageSize));
    }
    
  }
  changePage(page: number) {
     
    this.currenPage = page;
    var start = page*8;
    this.filteredArray = this.Jobs.slice(start-8 , start)
    // this.productService.GetProductsForUser(this.CategoryId, this.currenPage,+this.CityId)
    // .subscribe(data => {
    //   this.products = data;
    // });
  }
  incrementPage() {
    if (this.currenPage < this.pages.length) {
      this.currenPage ++ ;
      var start = this.currenPage*8;
      this.filteredArray = this.Jobs.slice(start-8 , start)
    //   console.log('display',this.display);
    //   this.productService.GetProductsForUser(this.CategoryId, this.currenPage,+this.CityId)
    //   .subscribe(data => {
    //     this.products = data;
    // });
    }
  }
  decrementPage() {
  if (this.currenPage > 1) {
    this.currenPage -- ;
    var start = this.currenPage*8;
    this.filteredArray = this.Jobs.slice(start-8 , start)
    // console.log('display',this.display);
    // this.productService.GetProductsForUser(this.CategoryId, this.currenPage,+this.CityId)
    // .subscribe(data => {
    //   this.products = data;
    // });
  }
  }
}
