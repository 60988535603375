import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ProductService } from '../product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductModel } from '../models/Product.model';
import { CategoryService } from 'src/app/services/category.service';
import { CategoryModel } from '../models/category.model';
import { CartOneComponent } from '../../cart/cart-one/cart-one.component';
import { SessionService } from '../../session/session.service';
@Component({
  selector: 'app-shop-one',
  templateUrl: './shop-one.component.html',
  styleUrls: ['./shop-one.component.scss']
})
export class ShopOneComponent implements OnInit {
  @ViewChild('swap',{static:false}) swap: ElementRef;

  products: ProductModel[] = [];
  AllProducts: ProductModel[] = [];
  categories: CategoryModel[] = [];
  count = 0;
  currenPage = 1;
  pages: number [] = [] ;
  public current_language;
  CityId = '';
  pageOfItems: Array<any>;
  items = [];

  filteredArray: any[] = []
  defaultRecords: any = 4;
  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private sessionService:SessionService
  ) { 
    this.CategoryId = + route.snapshot.paramMap.get('Id');
    this.currenPage = + route.snapshot.paramMap.get('page');
    this.current_language = localStorage.getItem('lang');
  }
  CategoryId = 0;
  display="grid_4column";
  changeDisplay(display){
    this.display = display;
  }
  onPaginateChange(data) {
     
    if(data.pageIndex > data.previousPageIndex){
      this.filteredArray = this.products.slice((data.previousPageIndex * data.pageSize), (data.pageIndex * data.pageSize));
    }
    else{
      this.filteredArray = this.products.slice((data.pageIndex * data.pageSize), (data.previousPageIndex * data.pageSize));
    }
    
  }
  ngOnInit() {
    if(this.sessionService.loggedIn == true){
      this.CityId = '0';
    }
    else{
      this.CityId = localStorage.getItem('CityId');
    }
    // this.productService.GetCountByCategoryId(this.CategoryId,+this.CityId)
    // .subscribe(count => {
    //   this.count = count;
    //   let length = this.count / 8;
    //   if(length % 8){
    //     length ++;
    //   }
    //   for (let index = 1; index <=  length ; index ++) {
    //     this.pages.push(index);
    //    }
    // });
    this.productService.GetProductsForUser(this.CategoryId, 1)
    .subscribe(data => {
       
      this.products = data;
      this.filteredArray = [];
      this.filteredArray = this.products.slice(0, 8);
      // this.products = Array(this.products.length).fill(0).map((x, i) => (new ProductModel({amount:x.amount,categoryNameAr : x.categoryNameAr,categoryNameEn:x.categoryNameEn,imageUrl:x.imageUrl,images:x.images,price:x.price,productId:x.productId,productNameAr:x.productNameAr,productNameEn:x.productNameEn,productUnits:x.productUnits,quantity:x.quantity,smallDescriptionAr:x.smallDescriptionAr,smallDescriptionEn:x.smallDescriptionEn})));
      this.count = this.products.length;
      let length = this.count / 8;
      if(length % 8){
        length ++;
      }
      for (let index = 1; index <=  length ; index ++) {
        this.pages.push(index);
       }
      this.products.forEach(element => {
        element.amount = 1;
      });
    });
    this.categoryService.GetAllForDropdownMenu()
    .subscribe(data => {
      this.categories = data;
      this.categories.forEach(element => {
        if(element.hideFromDropdownMenu == true){
          var i = this.categories.findIndex(x=>x.id == element.id);
          if(i > -1){
            this.categories.splice(i,1);
          }
        }
      });
    });
  }
  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
}
  getByCategoryId(){
    this.currenPage = 1;
    this.pages=[];
    // this.productService.GetCountByCategoryId(this.CategoryId,+this.CityId)
    // .subscribe(count => {
    //   this.count = count;
    // });
    this.productService.GetProductsForUser(this.CategoryId, this.currenPage)
    .subscribe(data => {
      this.products = data;
      this.filteredArray = [];
      this.filteredArray = this.products.slice(0, 8);
      // this.products = Array(this.products.length).fill(0).map((x, i) => (new ProductModel({amount:x.amount,categoryNameAr : x.categoryNameAr,categoryNameEn:x.categoryNameEn,imageUrl:x.imageUrl,images:x.images,price:x.price,productId:x.productId,productNameAr:x.productNameAr,productNameEn:x.productNameEn,productUnits:x.productUnits,quantity:x.quantity,smallDescriptionAr:x.smallDescriptionAr,smallDescriptionEn:x.smallDescriptionEn})));
      this.count = this.products.length;
      let length = this.count / 8;
      if(length % 8){
        length ++;
      }
      for (let index = 1; index <=  length ; index ++) {
        this.pages.push(index);
       }
      this.products.forEach(element => {
        element.amount = 1;
      });
    });
  }
  changePage(page: number) {
     
    this.currenPage = page;
    var start = page*8;
    this.filteredArray = this.products.slice(start-8 , start)
    // this.productService.GetProductsForUser(this.CategoryId, this.currenPage,+this.CityId)
    // .subscribe(data => {
    //   this.products = data;
    // });
  }
  incrementPage() {
    if (this.currenPage < this.pages.length) {
      this.currenPage ++ ;
      var start = this.currenPage*8;
      this.filteredArray = this.products.slice(start-8 , start)
    //   console.log('display',this.display);
    //   this.productService.GetProductsForUser(this.CategoryId, this.currenPage,+this.CityId)
    //   .subscribe(data => {
    //     this.products = data;
    // });
    }
  }
  decrementPage() {
  if (this.currenPage > 1) {
    this.currenPage -- ;
    var start = this.currenPage*8;
    this.filteredArray = this.products.slice(start-8 , start)
    // console.log('display',this.display);
    // this.productService.GetProductsForUser(this.CategoryId, this.currenPage,+this.CityId)
    // .subscribe(data => {
    //   this.products = data;
    // });
  }
  }

}
