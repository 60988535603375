import { CartModel } from "../../user-account/models/Cart.model";

export class CheckQuantityModel{
    cityId?:number;
    collection?:CartModel[];
    constructor(model ?: CheckQuantityModel){
        model = model ? model : {};
        this.cityId = model.cityId ? model.cityId : 0;
        this.collection = model.collection ? model.collection : [];
    }
}