import { Component, OnInit } from '@angular/core';
import { OrderService } from 'src/app/services/order.service';
import { CheckoutModel } from '../../checkout/checkout.model';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  orders: CheckoutModel[] = [];
  constructor(private orderService: OrderService) { }
  count = 0;
  currenPage = 1;
  pages: number [] = [] ;
  ngOnInit() {
    this.orderService.GetForCurrentUser()
    .subscribe(data => {
      this.orders = data;
      let length = this.orders.length / 8;
      if(length % 8){
        length ++;
      }
      for (let index = 1; index <=  length ; index ++) {
        this.pages.push(index);
       }
    });
  }
  incrementPage() {
    if (this.currenPage < this.pages.length) {
      this.currenPage ++ ;
    }
  }
  decrementPage() {
  if (this.currenPage > 1) {
    this.currenPage -- ;
  }
  }
  changePage(page: number) {
    this.currenPage = page;
  }
}
