import { ConsigneeModel } from './Consignee.model';
import { CustomerAddressModel } from "./CustomerAddressModel .model";
import { InvoiceItemModel } from "./InvoiceItemModel .model";
import { InvoiceSuppliersModel } from './InvoiceSuppliers.model';
import { RecurringModel } from "./RecurringModel.model";

export class ExecutePaymentRequestModel{
    PaymentMethodId     ?: number;
    SessionId           ?: string; //optional
    RecurringModel      ?: RecurringModel ; //optional
    CustomerName        ?: string; //optional
    DisplayCurrencyIso  ?: string; //optional
    MobileCountryCode   ?: string; //optional
    CustomerMobile      ?: string; //optional
    CustomerEmail       ?: string; //optional
    InvoiceValue        ?: number;
    Language            ?: string; //optional
    CustomerReference   ?: string; //optional
    CustomerCivilId     ?: string; //optional
    UserDefinedField    ?: string; //optional
    CallBackUrl         ?: string; //optional
    ErrorUrl            ?: string; //optional
    CustomerAddress     ?: CustomerAddressModel ; //optional
    ExpiryDate          ?: string; //optional
    InvoiceItems        ?: InvoiceItemModel[]; //optional
    ShippingMethod      ?: number ;//optional
    ShippingConsignee   ?: ConsigneeModel; //optional
    Suppliers           ?: InvoiceSuppliersModel[]; //optional
    constructor(item ?: ExecutePaymentRequestModel){
        item = item ? item : {};
        this.PaymentMethodId = item.PaymentMethodId ? item.PaymentMethodId : 0;
        this.SessionId            = item.SessionId            ? item.SessionId            : '';
        this.RecurringModel       = item.RecurringModel       ? item.RecurringModel       : {};
        this.CustomerName         = item.CustomerName         ? item.CustomerName         : '';
        this.DisplayCurrencyIso   = item.DisplayCurrencyIso   ? item.DisplayCurrencyIso   : '';
        this.MobileCountryCode    = item.MobileCountryCode    ? item.MobileCountryCode    : '';
        this.CustomerMobile       = item.CustomerMobile       ? item.CustomerMobile       : '';
        this.CustomerEmail        = item.CustomerEmail        ? item.CustomerEmail        : '';
        this.InvoiceValue         = item.InvoiceValue         ? item.InvoiceValue         : 0;
        this.Language             = item.Language             ? item.Language             : '';
        this.CustomerReference    = item.CustomerReference    ? item.CustomerReference    : '';
        this.CustomerCivilId      = item.CustomerCivilId      ? item.CustomerCivilId      : '';
        this.UserDefinedField     = item.UserDefinedField     ? item.UserDefinedField     : '';
        this.CallBackUrl          = item.CallBackUrl          ? item.CallBackUrl          : '';
        this.ErrorUrl             = item.ErrorUrl             ? item.ErrorUrl             : '';
        this.CustomerAddress      = item.CustomerAddress      ? item.CustomerAddress      : {};
        this.ExpiryDate           = item.ExpiryDate           ? item.ExpiryDate           : '';
        this.InvoiceItems         = item.InvoiceItems         ? item.InvoiceItems         : [];
        this.ShippingMethod       = item.ShippingMethod       ? item.ShippingMethod       : 0;
        this.ShippingConsignee    = item.ShippingConsignee    ? item.ShippingConsignee    : {};
        this.Suppliers            = item.Suppliers            ? item.Suppliers            : [];
    }
}