import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { FooterModule } from 'src/app/layouts/footer/footer.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckoutAsAGuestComponent } from './checkout-as-a-guest.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { HeaderModule } from 'src/app/layouts/header/header.module';



@NgModule({
  declarations: [
    CheckoutAsAGuestComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule,
    // NgSelectModule,
    ComponentsModule,
    HeaderModule,
    FooterModule,
    FormsModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'})
  ],
})
export class CheckoutAsAGuestModule { }
