import { Component, OnInit } from '@angular/core';
import { WishListService } from 'src/app/pages/user-account/services/wish-list.service';
import { KatoraService } from 'src/app/services/katora.service';

@Component({
  selector: 'app-wishlist-button',
  templateUrl: './wishlist-button.component.html',
  styleUrls: ['./wishlist-button.component.scss']
})
export class WishlistButtonComponent implements OnInit {

  public productAddedToWishlist;
  public productTotal;
  public wishlistCount = 0;
  constructor(
    private katoraService: KatoraService,
    private wishListService: WishListService
    ) { 
  }
  ngOnInit() {
    this.wishlistCount = this.katoraService.getWishlistCount();
    this.productAddedToWishlist = this.wishListService.getProductFromWishlist();
  }
  ngAfterContentChecked(){
    this.productAddedToWishlist = this.wishListService.getProductFromWishlist();
    this.wishlistCount = this.katoraService.getWishlistCount();
  }

}
