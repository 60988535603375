import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PurchaseCouponModel } from '../../models/purchase-coupon.model';
import { PurchaseCouponService } from '../../services/purchase-coupon.service';

@Component({
    // moduleId: module.id,
    selector: 'create-purchase-coupon',
    templateUrl: 'create-purchase-coupon.component.html',
    styleUrls: ['create-purchase-coupon.component.scss']
})
export class CreatePurchaseCouponComponent implements OnInit {
   coupon: PurchaseCouponModel = {};
   Form: FormGroup;
   constructor(private purchaseService: PurchaseCouponService, private fb: FormBuilder , public activeModal: NgbActiveModal) {
   }
    createForm(): FormGroup {
     return this.fb.group({
       id: [this.coupon.id],
       amount: [this.coupon.amount, Validators.required],
       code: [this.coupon.code],
       isUsed: [this.coupon.isUsed],
       paymentType: [this.coupon.paymentType],
       usedBy: [this.coupon.usedBy],
       usedDate: [this.coupon.usedDate],
     });
   }
   ngOnInit() {
     this.coupon = new PurchaseCouponModel();
     this.Form = this.createForm();
   }
   onSubmit() {
     this.coupon = this.Form.getRawValue();
     this.purchaseService.createPurchaseCoupon(this.coupon)
     .subscribe(data => {
       if ( data === true ) {
       }
     });
   }
}
