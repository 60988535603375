import { Component, OnDestroy, OnInit, Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription, timer } from 'rxjs';
import { UserModel } from '../../user-account/models/user.model';
import { UserService } from '../../user-account/services/user.service';
import { UserRegistration } from '../model/user-registration';
import { SessionService } from '../session.service';

@Component({
    // moduleId: module.id,
    selector: 'confirm-code',
    templateUrl: 'confirm-code.component.html',
    styleUrls: ['confirm-code.component.scss']
})
export class ConfirmCodeComponent implements OnInit, OnDestroy {
  confirmForm: FormGroup;
  openForm = false;
  userId = 0;
  user: UserModel = {};
  UserRegistration: UserRegistration = {};
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private userService: UserService,
    private sessionService: SessionService,
    private router: Router ,
    public toaster: ToastrService,
    ){
        this.userId = + this.route.snapshot.paramMap.get('Id');
    }
    time = 5000;
    resend=false;
    startDate=new Date();
    endDate=new Date().setMinutes(5);
    triggerFunction(){
        return new Date();
    }
    countDown:Subscription;
    counter = 100;
    tick = 1000;
  
    ngOnInit(): void {
      //debugger;
        this.countDown = timer(0, this.tick)
        .subscribe(() => --this.counter)
        this.userService.GetById(this.userId)
        .subscribe(data => {
            this.user = data;
            if(this.user.code != 0){
              if(this.user.isSaudi == true){
                 
                if(this.user.code != 0){
                  this.openForm = true;
                  this.confirmForm = this.formBuilder.group({
                      code : ['' , Validators.required]
                    });
                  this.sessionService.sendSMS(this.user.code,this.user.phone)
                  .subscribe(result => {
                 
                  })
            
                }
                else{
                  this.ResendCode();
                }
             
              }
              else{
                this.openForm = true;
                this.confirmForm = this.formBuilder.group({
                    code : ['' , Validators.required]
                  });
              }
       
            }
          else{
            this.ResendCode();
          }
           
        });
   

    }
    
    resentCode(){
        this.resend = true;
    }
    ResendCode(){
       //debugger;
        this.counter = 100;
        this.userService.ResentCodeAsync(this.userId)
        .subscribe(data => {
          this.user = new UserModel();
            this.user = data;
            if(this.user.isSaudi == true){
              this.openForm = true;
              this.countDown = timer(0, this.tick)
              .subscribe(() => --this.counter)
              this.confirmForm = this.formBuilder.group({
                  code : ['' , Validators.required]
                });
              this.sessionService.sendSMS(this.user.code,this.user.phone)
              .subscribe(result => {
          
              })
         
            }
            else{
              this.openForm = true;
              this.countDown = timer(0, this.tick)
              .subscribe(() => --this.counter)
              this.confirmForm = this.formBuilder.group({
                  code : ['' , Validators.required]
                });
            }
        });
    }
    submit() {
      
        const formData = this.confirmForm.getRawValue();
        if(this.user.code == formData.code){
            this.UserRegistration.address = this.user.address;
            this.UserRegistration.confirmPassword = this.user.password;
            this.UserRegistration.email = this.user.email;
            this.UserRegistration.gender = this.user.gender;
            this.UserRegistration.isActive = true;
            this.UserRegistration.name = this.user.email;
            this.UserRegistration.password = this.user.password;
            this.UserRegistration.phone = this.user.phone;
            this.UserRegistration.userName = this.user.email;
            this.UserRegistration.cityId = this.user.cityId;
            this.UserRegistration.reagionId = this.user.reagionId;
            this.sessionService.ConfirmRegister(this.UserRegistration)
            .subscribe(data => {
              if (data != null) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('currentUser', JSON.stringify(data));
                this.router.navigate(['']);
                this.sessionService.loggedIn = true;
                this.toaster.success('Welcome');
               }
               else{
                this.toaster.error('please enter the true code');
               }
            });
        }
        else{
            this.toaster.error('please enter the true code');
           }
    }
    ngOnDestroy(){
        this.countDown=null;
      }

}
@Pipe({
    name: "formatTime"
  })
  export class FormatTimePipe implements PipeTransform {
    transform(value: number): string {
      const minutes: number = Math.floor(value / 60);
      return (
        ("00" + minutes).slice(-2) +
        ":" +
        ("00" + Math.floor(value - minutes * 60)).slice(-2)
      );
    }
  }
