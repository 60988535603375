import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { DatePipe } from '@angular/common';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
class Product{    
    name: string;    
    price: number;    
    pricebefortax: number;    
    qty: number; 
  }    
  class Invoice{    
    customerName: string;    
    address: string;    
    contactNo: number;    
    email: string;    
    products: Product[] = [];    
    additionalDetails: string;    
    orderNumber  : string;  
    tax = 0;
    deleveryPrice = 0;
    copenCode : number = 0;
    balanceUsed : number = 0;
    date:Date;
    total = 0;
    constructor(){    
      // Initially one empty product row we will show     
      this.products.push(new Product());    
    }    
  }  
  @Component({
    selector: 'invoice',
    templateUrl: 'invoice.component.html',
    styleUrls: ['invoice.component.scss'],
    providers: [DatePipe]
})
export class InvoiceComponent implements OnInit,AfterViewInit {
  @ViewChild('contentToConvert', {static: false}) screen: ElementRef;
  @ViewChild('canvas', {static: false}) canvas: ElementRef;
  @ViewChild('downloadLink', {static: false}) downloadLink: ElementRef;
  downloadImage(){
    html2canvas(this.screen.nativeElement).then(canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = 'marble-diagram.png';
      this.downloadLink.nativeElement.click();
    });
  }
  Id = 0;
  qrCodeB64:string = '';
  public productTotal = 0;
    public productTotalBeforeVat = 0;
    public subtotalAfterDiscound = 0;
    public total = 0;
    public couponCode = 0;
    public tax = 0;
    // public flatRate = 100;
    public deleveryPrice = 0;
    public deleveryPriceVat = 0;
    checkoutid :string='';
 constructor(
  private router:Router,
  private route: ActivatedRoute,
  private orderService:OrderService,
  private datePipe: DatePipe
 ){
  this.Id = + route.snapshot.paramMap.get('Id');
  
 }
    ngAfterViewInit(): void {
      throw new Error('Method not implemented.');
    }
    ngOnInit(): void {
      this.orderService.UpdateAfterPay(this.Id)

      .subscribe(data => {
        if(data == true){
          this.orderService.GetInvoiceByOrderId(this.Id)
          .subscribe(data => {
            //debugger;
            this.invoice = data;
            this.productTotalBeforeVat = (+this.invoice.products.reduce((sum, p)=> sum + (p.qty * p.price), 0).toFixed(2))/1.15;
            this.couponCode = this.invoice.copenCode;
            this.subtotalAfterDiscound = this.productTotalBeforeVat- this.invoice.copenCode;
            this.productTotal = (+this.invoice.products.reduce((sum, p)=> sum + (p.qty * p.price), 0).toFixed(2));
            this.productTotal -=  this.invoice.copenCode;
            this.deleveryPriceVat = (this.invoice.deleveryPrice/100)* 15  ;
            this.total = (+this.invoice.products.reduce((sum, p)=> sum + (p.qty * p.price), 0).toFixed(2)) + this.invoice.deleveryPrice  + this.deleveryPriceVat - this.invoice.copenCode - this.invoice.balanceUsed;
            this.invoice.tax = 0;
            // this.invoice.products.forEach(element => {
            //   // var t =  (element.price/100)* 15
            //   element.pricebefortax = element.price/1.15;
            //   this.invoice.tax += element.price -element.pricebefortax
            // });
            // var vat = this.subtotalAfterDiscound / 1.15;
            // this.invoice.tax = this.subtotalAfterDiscound - vat;
            this.productTotal = this.subtotalAfterDiscound * 1.15;
            this.total = this.productTotal + this.invoice.deleveryPrice  + this.deleveryPriceVat - this.invoice.balanceUsed;
            var vat= this.productTotal / 1.15;
            this.invoice.tax = this.productTotal - vat;
            localStorage.removeItem('cartProducts');
            localStorage.removeItem('cartboxes');
            var sellerNameBuf=this.getTLVForValue("1","International Chocolate Trading Company");
            // var sellerNameBuf=this.getTLVForValue("1","شركة الشوكولاتة العالمية التجارية");
            var vatRegistrationNameBuf = this.getTLVForValue("2","310387548200003");
            var timeBuf = this.getTLVForValue("3",this.datePipe.transform(this.invoice.date, 'yyyy-MM-dd, h:mm a'));
            var totalBuf = this.getTLVForValue("4",this.total.toFixed(2));
            var totalvat = this.invoice.tax + this.deleveryPriceVat;
            var totalVATBuf = this.getTLVForValue("5",totalvat.toFixed(2));
            var tagBufsArray=[sellerNameBuf,vatRegistrationNameBuf,timeBuf,totalBuf,totalVATBuf];
             var Buffer = require('buffer/').Buffer
            var qrCodeBuf = Buffer.concat(tagBufsArray);
            this.qrCodeB64 = qrCodeBuf.toString('base64');
            this.openInvoice = true;
          })
        }
      })
      // this.orderService.GetStuats(this.Id)
      // .subscribe(result => {
      //   if(
      //     result.result.code == '000.000.000' ||
      //     result.result.code == '000.000.100' ||
      //     result.result.code == '000.100.105' ||
      //     result.result.code == '000.100.106' ||
      //     result.result.code == '000.100.110' ||
      //     result.result.code == '000.100.111' ||
      //     result.result.code == '000.100.112' ||
      //     result.result.code == '000.300.000' ||
      //     result.result.code == '000.300.100' ||
      //     result.result.code == '000.300.101' ||
      //     result.result.code == '000.300.102' ||
      //     result.result.code == '000.300.103' ||
      //     result.result.code == '000.310.100' ||
      //     result.result.code == '000.310.101' ||
      //     result.result.code == '000.310.110' ||
      //     result.result.code == '000.600.000' || 
      //     result.result.code == '000.400.000' || 
      //     result.result.code == '000.400.010' || 
      //     result.result.code == '000.400.020' || 
      //     result.result.code == '000.400.040' || 
      //     result.result.code == '000.400.050' || 
      //     result.result.code == '000.400.060' || 
      //     result.result.code == '000.400.070' || 
      //     result.result.code == '000.400.080' || 
      //     result.result.code == '000.400.081' || 
      //     result.result.code == '000.400.082' || 
      //     result.result.code == '000.400.090' || 
      //     result.result.code == '000.400.100' || 
      //     result.result.code == '000.400.110' ||
      //     result.result.code == '200.300.404'
      //     ){
      //       this.orderService.UpdateAfterPay(this.Id)

      //       .subscribe(data => {
      //         console.log(data);
      //         if(data == true){
      //           this.orderService.GetInvoiceByOrderId(this.Id)
      //           .subscribe(data => {
      //             //debugger;
      //             this.invoice = data;
      //             this.productTotalBeforeVat = (+this.invoice.products.reduce((sum, p)=> sum + (p.qty * p.price), 0).toFixed(2))/1.15;
      //             this.couponCode = this.invoice.copenCode;
      //             this.subtotalAfterDiscound = this.productTotalBeforeVat- this.invoice.copenCode;
      //             this.productTotal = (+this.invoice.products.reduce((sum, p)=> sum + (p.qty * p.price), 0).toFixed(2));
      //             this.productTotal -=  this.invoice.copenCode;
      //             this.deleveryPriceVat = (this.invoice.deleveryPrice/100)* 15  ;
      //             this.total = (+this.invoice.products.reduce((sum, p)=> sum + (p.qty * p.price), 0).toFixed(2)) + this.invoice.deleveryPrice  + this.deleveryPriceVat - this.invoice.copenCode - this.invoice.balanceUsed;
      //             this.invoice.tax = 0;
      //             // this.invoice.products.forEach(element => {
      //             //   // var t =  (element.price/100)* 15
      //             //   element.pricebefortax = element.price/1.15;
      //             //   this.invoice.tax += element.price -element.pricebefortax
      //             // });
      //             // var vat = this.subtotalAfterDiscound / 1.15;
      //             // this.invoice.tax = this.subtotalAfterDiscound - vat;
      //             this.productTotal = this.subtotalAfterDiscound * 1.15;
      //             this.total = this.productTotal + this.invoice.deleveryPrice  + this.deleveryPriceVat - this.invoice.balanceUsed;
      //             var vat= this.productTotal / 1.15;
      //             this.invoice.tax = this.productTotal - vat;
      //             console.log(this.invoice);
      //             localStorage.removeItem('cartProducts');
      //             var sellerNameBuf=this.getTLVForValue("1","International Chocolate Trading Company");
      //             // var sellerNameBuf=this.getTLVForValue("1","شركة الشوكولاتة العالمية التجارية");
      //             var vatRegistrationNameBuf = this.getTLVForValue("2","310387548200003");
      //             var timeBuf = this.getTLVForValue("3",this.datePipe.transform(this.invoice.date, 'yyyy-MM-dd, h:mm a'));
      //             var totalBuf = this.getTLVForValue("4",this.total.toFixed(2));
      //             var totalvat = this.invoice.tax + this.deleveryPriceVat;
      //             var totalVATBuf = this.getTLVForValue("5",totalvat.toFixed(2));
      //             var tagBufsArray=[sellerNameBuf,vatRegistrationNameBuf,timeBuf,totalBuf,totalVATBuf];
      //              var Buffer = require('buffer/').Buffer
      //             var qrCodeBuf = Buffer.concat(tagBufsArray);
      //             this.qrCodeB64 = qrCodeBuf.toString('base64');
      //             this.openInvoice = true;
      //           })
      //         }
      //       })
      //     }
      //     else{
      //       this.router.navigate(['error-on-pay'])
      //     }
          
      // })
   
      // this.orderService.UpdateAfterPay(this.Id)

      //       .subscribe(data => {
      //         console.log(data);
      //         if(data == true){
      //           this.orderService.GetInvoiceByOrderId(this.Id)
      //           .subscribe(data => {
      //             //debugger;
      //             this.invoice = data;
      //             this.productTotalBeforeVat = (+this.invoice.products.reduce((sum, p)=> sum + (p.qty * p.price), 0).toFixed(2))/1.15;
      //             this.couponCode = this.invoice.copenCode;
      //             this.subtotalAfterDiscound = this.productTotalBeforeVat- this.invoice.copenCode;
      //             this.productTotal = (+this.invoice.products.reduce((sum, p)=> sum + (p.qty * p.price), 0).toFixed(2));
      //             this.productTotal -=  this.invoice.copenCode;
      //             this.deleveryPriceVat = (this.invoice.deleveryPrice/100)* 15  ;
      //             this.total = (+this.invoice.products.reduce((sum, p)=> sum + (p.qty * p.price), 0).toFixed(2)) + this.invoice.deleveryPrice  + this.deleveryPriceVat - this.invoice.copenCode - this.invoice.balanceUsed;
      //             this.invoice.tax = 0;
      //             // this.invoice.products.forEach(element => {
      //             //   // var t =  (element.price/100)* 15
      //             //   element.pricebefortax = element.price/1.15;
      //             //   this.invoice.tax += element.price -element.pricebefortax
      //             // });
      //             // var vat = this.subtotalAfterDiscound / 1.15;
      //             // this.invoice.tax = this.subtotalAfterDiscound - vat;
      //             this.productTotal = this.subtotalAfterDiscound * 1.15;
      //             this.total = this.productTotal + this.invoice.deleveryPrice  + this.deleveryPriceVat - this.invoice.balanceUsed;
      //             var vat= this.productTotal / 1.15;
      //             this.invoice.tax = this.productTotal - vat;
      //             console.log(this.invoice);
      //             localStorage.removeItem('cartProducts');
      //             var sellerNameBuf=this.getTLVForValue("1","International Chocolate Trading Company");
      //             // var sellerNameBuf=this.getTLVForValue("1","شركة الشوكولاتة العالمية التجارية");
      //             var vatRegistrationNameBuf = this.getTLVForValue("2","310387548200003");
      //             var timeBuf = this.getTLVForValue("3",this.datePipe.transform(this.invoice.date, 'yyyy-MM-dd, h:mm a'));
      //             var totalBuf = this.getTLVForValue("4",this.total.toFixed(2));
      //             var totalvat = this.invoice.tax + this.deleveryPriceVat;
      //             var totalVATBuf = this.getTLVForValue("5",totalvat.toFixed(2));
      //             var tagBufsArray=[sellerNameBuf,vatRegistrationNameBuf,timeBuf,totalBuf,totalVATBuf];
      //              var Buffer = require('buffer/').Buffer
      //             var qrCodeBuf = Buffer.concat(tagBufsArray);
      //             this.qrCodeB64 = qrCodeBuf.toString('base64');
      //             this.openInvoice = true;
      //           })
      //         }
      //       })
    }
    openInvoice=false;
  invoice = new Invoice(); 
  
  // generatePDF(action = 'open') {
     
  //   let docDefinition = {
  //     content: [
  //       {
  //         text: 'Laderach',
  //         fontSize: 16,
  //         alignment: 'center',
  //         color: '#047886'
  //       },
  //       {
  //         text: 'INVOICE',
  //         fontSize: 20,
  //         bold: true,
  //         alignment: 'center',
  //         decoration: 'underline',
  //         color: 'skyblue'
  //       },
  //       {
  //         text: 'Customer Details',
  //         style: 'sectionHeader'
  //       },
        
        
  //       {
  //         columns: [
  //           [
  //             {
  //               text: this.invoice.customerName,
  //               bold:true
  //             },
  //             { text: this.invoice.address },
  //             { text: this.invoice.email },
  //             { text: this.invoice.contactNo }
  //           ],
  //           [
  //             {
  //               text: `Date: ${new Date(this.invoice.date).toLocaleString()}`,
  //               alignment: 'right'
  //             }
  //           ]
  //         ]
  //       },
  //       {
  //         text: 'Products Details',
  //         style: 'sectionHeader'
  //       },
  //       {
  //         table: {
  //           headerRows: 1,
  //           widths: ['*', 'auto', 'auto', 'auto'],
  //           body: [
  //             ['Product', 'Price', 'Quantity', 'Amount'],
  //             ...this.invoice.products.map(p => ([p.name, p.price, p.qty, (p.price*p.qty).toFixed(2)])),
  //             [{text: 'Total Amount', colSpan: 3}, {}, {}, (+this.invoice.products.reduce((sum, p)=> sum + (p.qty * p.price), 0).toFixed(2)) + this.invoice.deleveryPrice + this.invoice.tax - this.invoice.copenCode - this.invoice.balanceUsed]
  //           ]
  //         }
  //       },
  //       {
  //         text: 'Order Details',
  //         style: 'sectionHeader'
  //       },
  //       {
  //         text: 'Order Number : #' + this.invoice.orderNumber
  //       },
  //       {
  //         table: {
  //           headerRows: 1,
  //           widths: ['*', 'auto'],
  //           body: [
  //             ['Coupon Code', this.invoice.copenCode],
  //             ['TAX', this.invoice.tax],
  //             ['Delevery Price', this.invoice.deleveryPrice],
  //             ['Balance', this.invoice.balanceUsed]
  //           ]
  //         }
  //       },
       
  //       {
  //         text: 'QR',
  //         style: 'sectionHeader'
  //       },
  //       {
  //         columns: [
  //           [{ qr: this.qrCodeB64, fit: '150'
  //         }],
  //           [{ text: 'Signature', alignment: 'right', italics: true}],
  //         ]
  //       },
  //       // {
  //       //   text: 'Terms and Conditions',
  //       //   style: 'sectionHeader'
  //       // },
  //       // {
  //       //     ul: [
  //       //       'Order can be return in max 10 days.',
  //       //       'Warrenty of the product will be subject to the manufacturer terms and conditions.',
  //       //       'This is system generated invoice.',
  //       //     ],
  //       // }
  //     ],
  //     styles: {
  //       sectionHeader: {
  //         bold: true,
  //         decoration: 'underline',
  //         fontSize: 14,
  //         margin: [0, 15,0, 15]          
  //       }
  //     }
  //   };

  //   if(action==='download'){
  //     pdfMake.createPdf(docDefinition).download();
  //   }else if(action === 'print'){
  //     pdfMake.createPdf(docDefinition).print();      
  //   }else{
  //     pdfMake.createPdf(docDefinition).open();      
  //   }

  // }
  b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode();
    }));
}
  addProduct(){
     
    this.invoice.products.push(new Product());
  } 
  getTLVForValue(tagNum,tagValue){
    //debugger;
    var Buffer = require('buffer/').Buffer
    var tagBuf=Buffer.from([tagNum],'utf8');
    var tagValueLenBuf=Buffer.from([tagValue.length],'utf8');
    var tagValueBuf=Buffer.from(tagValue,'utf8');
    var bufArray = [tagBuf,tagValueLenBuf,tagValueBuf]
    return Buffer.concat(bufArray);
  }
  generatePDF1(action = 'open') {
    var data = this.screen.nativeElement;
    // html2canvas.useCORS = true;
    html2canvas(data, {useCORS : true, logging: true, allowTaint: true }).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0 , position, imgWidth, imgHeight)
      // pdf.addFont('/assets/Janna-LT-Bold.ttf', 'Janna-LT-Bold', 'normal');
      // pdf.setFont('Janna-LT-Bold');
      if(action==='download'){
        pdf.save('newPDF.pdf');

      }else if(action === 'print'){
        pdf.output('datauristring');      
      }else{
        pdf.output('dataurlnewwindow');
      }
    });
  }
  public convetToPDF()
  {
    //debugger;
  var data = document.getElementById('contentToConvert');
    // html2canvas.useCORS = true;
  html2canvas(data,{}).then(canvas => {
  // Few necessary setting options
  var imgWidth = 208;
  var pageHeight = 295;
  var imgHeight = canvas.height * imgWidth / canvas.width;
  var heightLeft = imgHeight;
  const contentDataURL = canvas.toDataURL('image/png')
  let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
  var position = 0;
  pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
  pdf.save('new-file.pdf'); // Generated PDF
  
});

  }
}
