import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Slider2PhotoModel } from 'src/app/models/slider2-photo.model';
import { Slider2PhotoService } from 'src/app/services/slider2-photo.service';

@Component({
  selector: 'app-full-screen-banner',
  templateUrl: './full-screen-banner.component.html',
  styleUrls: ['./full-screen-banner.component.scss']
})
export class FullScreenBannerComponent implements OnInit {

  constructor(
    private slider2PhotoService: Slider2PhotoService,
    private sanitization: DomSanitizer,
  ) { }
  photo: Slider2PhotoModel = {};
  openSlider = false;
  safeImage = {};
  ngOnInit() {
    this.slider2PhotoService.GetPhoto()
    .subscribe(photo => {
      this.photo = photo;
      if(this.photo.type == 1){
        this.openSlider = true;
        this.safeImage = this.sanitization.bypassSecurityTrustStyle(`url(${photo.imageUrl})`);
      }
      else if(this.photo.type == 2){
        this.openSlider = true;
      }
      else{
        this.openSlider = false;
      }
    });
  }

}
