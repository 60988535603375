import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UserRegistration } from 'src/app/pages/session/model/user-registration';
import { CategoryModel } from 'src/app/pages/shop/models/category.model';
import { CategoryService } from 'src/app/services/category.service';


@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {
  isIn:boolean=false;
  name:string;
  user: UserRegistration = {};
  categories: CategoryModel [] = [];
  searchName = '';
  CategoryId = 0;
  CategoryName = 'All Categories';
  Form: FormGroup;
  public current_language;
  constructor(
    private categoryService: CategoryService,
    private router: Router,
    private fb: FormBuilder
  ){
    this.current_language = localStorage.getItem('lang');
  }
  createForm(): FormGroup {
    return this.fb.group({
      categoryId: [this.CategoryId],
      searchName: [this.searchName]
    });
  }
  ngOnInit() {
    this.Form = this.createForm();
    this.categoryService.GetAllForDropdownMenu()
    .subscribe(data => {
      this.categories = data;
      this.categories.forEach(element => {
        if(element.hideFromDropdownMenu == true){
          var i = this.categories.findIndex(x=>x.id == element.id);
          if(i > -1){
            this.categories.splice(i,1);
          }
        }
      });
      this.categories.splice( 0 , 0 , {id: 0, nameEn: 'All Categories',nameAr:'جميع الفئات'});
    });
    const token  = localStorage.getItem('token');
    if (token != null && token != '') {
      const user = JSON.parse(window.atob(token.split('.')[1]));
      this.isIn = true;
      this.name = user.UserName
    }
    else{
      this.name = '';
    }
  }
  setCategoryId(categoryId: number , CategoryName: string){
    this.CategoryId = categoryId;
    var i = this.categories.findIndex(x=>x.id == categoryId);
    if(i != -1){
      if(this.current_language == 'ar'){
        this.CategoryName = this.categories[i].nameAr;
      }else
      {
        this.CategoryName = this.categories[i].nameEn;
      }
    }
    else{
      this.CategoryName = CategoryName;
    }
  }
  search() {
    const item = this.Form.getRawValue();
    if (item.searchName === '') {
      item.searchName = ' ';
    }
      this.router.navigate([`shop-search/${this.CategoryId}/1/${item.searchName}`]);
  }
}
