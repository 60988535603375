export class BillingAddressModel{
    billingAddressFirstName?: string;
    billingAddressLastName?: string;
    billingAddressCompanyName?: string;
    billingAddressAddress?: string;
    billingAddressCityId?: number;
    billingAddressRegionId?: number;
    billingAddressCityName?: string;
    billingAddressRegionName?: string;
    billingAddressCity?: string;
    billingAddressPhone?: string;
    constructor(billing?:BillingAddressModel){
        billing = billing ? billing : {};
        this.billingAddressFirstName    = billing.billingAddressFirstName    ? billing.billingAddressFirstName    : '';
        this.billingAddressLastName     = billing.billingAddressLastName     ? billing.billingAddressLastName     : '';
        this.billingAddressCompanyName  = billing.billingAddressCompanyName  ? billing.billingAddressCompanyName  : '';
        this.billingAddressAddress      = billing.billingAddressAddress      ? billing.billingAddressAddress      : '';
        this.billingAddressCityId       = billing.billingAddressCityId       ? billing.billingAddressCityId       : 0;
        this.billingAddressRegionId     = billing.billingAddressRegionId     ? billing.billingAddressRegionId     : 0;
        this.billingAddressCityName     = billing.billingAddressCityName     ? billing.billingAddressCityName     : '';
        this.billingAddressRegionName   = billing.billingAddressRegionName   ? billing.billingAddressRegionName   : '';
        this.billingAddressCity         = billing.billingAddressCity         ? billing.billingAddressCity         : '';
        this.billingAddressPhone        = billing.billingAddressPhone        ? billing.billingAddressPhone        : '';
    }
}