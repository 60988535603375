import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ShoppingAddressModel } from '../models/shopping-address.model';

@Injectable({
  providedIn: 'root'
})
export class ShoppingAddressService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem('token');
   httpOptions = {
    headers: new HttpHeaders({
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {
  }
  GetForCurrentUser(): Observable<ShoppingAddressModel[]> {
    return this.http
      .get<ShoppingAddressModel[]>(`${this.baseUrl}/ShoppingAddress/GetForCurrentUser`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  createShoppingAddress(payload: ShoppingAddressModel): Observable<boolean> {
        return this.http
          .post<boolean>(`${this.baseUrl}/ShoppingAddress/Insert`, payload, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
 put(payload: ShoppingAddressModel): Observable<boolean> {
        return this.http
          .put<boolean>(`${this.baseUrl}/ShoppingAddress/Update`, payload, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
 setDefualt(payload: ShoppingAddressModel): Observable<boolean> {
        return this.http
          .put<boolean>(`${this.baseUrl}/ShoppingAddress/SetDefualt`, payload, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
 delete(payload: ShoppingAddressModel): Observable<boolean> {
        return this.http
          .delete<boolean>(`${this.baseUrl}/ShoppingAddress/Delete/${payload.id}`, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
}
