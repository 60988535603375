import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accept-cookie',
  templateUrl: './accept-cookie.component.html',
  styleUrls: ['./accept-cookie.component.scss']
})
export class AcceptCookieComponent implements OnInit {
  result = 'no';
  constructor() { }
  ngOnInit() {
    this.result = localStorage.getItem('cookies');
    if (this.result !== 'yes') {
      this.result = 'no';
    }
  }
  accept() {
    localStorage.setItem('cookies' , 'yes');
  }
}
