export class ChangePasswordModel{
    oldPassword    ?: string ;
    newPassword    ?: string ;
    confirmPassword?: string ;
    code?: string ;
    constructor(password ?: ChangePasswordModel){
        password = password ? password : {};
        this.code = password.code ? password.code : '';
        this.oldPassword     = password.oldPassword     ? password.oldPassword     : '';
        this.newPassword     = password.newPassword     ? password.newPassword     : '';
        this.confirmPassword = password.confirmPassword ? password.confirmPassword : '';
    }
}