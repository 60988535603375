import { Component, OnInit } from '@angular/core';
import { ContactModel } from 'src/app/models/contact.model';
import { ContactService } from 'src/app/pages/user-account/services/contact.service';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {
  contact: ContactModel = {};
  constructor(private contactService: ContactService) { }
  date=new Date().getFullYear();
  ngOnInit() {
    this.contactService.GetContact()
    .subscribe(data => {
      this.contact = data;
    });
  }

}
