import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { WishListModel } from '../models/wish-list.model';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class WishListService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem('token');
   public wishlistProducts = this.getProductFromWishlist();
   public count;
   httpOptions = {
    headers: new HttpHeaders({
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient,
              private toastr: ToastrService
    ) {
  
  }
  getProductFromWishlist() {
 
    if (localStorage.getItem('wishlistProducts') == null) {
      return [];
    }
    else
      return JSON.parse(localStorage.getItem('wishlistProducts'));
  }
  addProductToWishlist(products: any){
    let count = 0;
    this.wishlistProducts.forEach(element => {
      if (products.id == element.id) {
        count++;
      }
    });
    if (count == 0) {
      this.wishlistProducts.push(products);
      this.updateWishlistProduct()
    }
  }
  updateWishlistProduct() {
    localStorage.setItem('wishlistProducts', JSON.stringify(this.wishlistProducts));
  }
  removeProductFromWishlist(products){
     
    let count = 0;
    this.wishlistProducts.forEach((element) => {
      if (products.id == element.id) {
        this.wishlistProducts.splice(0, 1);
        count++;
      }
    });
    if (count != 0) {
      this.updateWishlistProduct()
    }
  }
  GetForCurrentUser(page: number): Observable<WishListModel[]> {
    return this.http
      .get<WishListModel[]>(`${this.baseUrl}/WishList/GetForCurrentUser/${page}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCount(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/WishList/GetCount`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  createWishList(payload: WishListModel): Observable<number> {
        return this.http
          .post<number>(`${this.baseUrl}/WishList/Insert`, payload, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
  InsertList(payload: WishListModel[]): Observable<number> {
        return this.http
          .post<number>(`${this.baseUrl}/WishList/InsertList`, payload, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
      
 put(payload: WishListModel): Observable<boolean> {
        return this.http
          .put<boolean>(`${this.baseUrl}/WishList/Update`, payload, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
 delete(payload: WishListModel): Observable<boolean> {
        return this.http
          .delete<boolean>(`${this.baseUrl}/WishList/Delete/${payload.id}`, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
}
