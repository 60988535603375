import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { BoxCartService } from '../pages/boxes/box-cart.service';
import { SessionService } from '../pages/session/session.service';
import { BoxCartModel } from '../pages/user-account/models/box-cart.model';
import { CartService } from '../pages/user-account/services/cart.service';
import { WishListService } from '../pages/user-account/services/wish-list.service';
import { CartModel } from '../pages/user-account/models/Cart.model';

@Injectable({
  providedIn: 'root'
})
export class KatoraService {
  public cartProducts = [];
  public cartBoxes = [];
  public wishlistProducts = [];
  public count;
  public total;
  public addQuantity;
  public removeQuantity;
  public alerts;
  public closeAlert;
  public giftCards = [];
  cards : CartModel[] = [];
  boxes : BoxCartModel[] = [];

  constructor( private toastr: ToastrService,
    private sessionService:SessionService,
    private cartService:CartService,
    private boxCartService:BoxCartService,
    private wishListService:WishListService ) {
    this.cartProducts = this.getProductFromCart();
    this.cartBoxes = this.getboxesFromCart();
    this.wishlistProducts = this.getProductFromWishlist();

  }

  calculateTotal(){
    let pTotal= 0; 
    this.getProductFromCart().forEach(element => {

      let p=element.quantity*element.totalPrice;

      pTotal=pTotal+p;
    });
    this.getboxesFromCart().forEach(element => {

      let p=element.quantity*element.totalPrice;

      pTotal=pTotal+p;
    });
    this.total =  pTotal;

    return pTotal;
  }
  getCartCount(){
    var count = this.cartProducts.length + this.cartBoxes.length;
    return count;
  }
  getWishlistCount(){
    return this.wishlistProducts.length
  }
  getProductFromCart() {
    if (localStorage.getItem('cartProducts') == null) {
      return [];
    }
    else
      return JSON.parse(localStorage.getItem('cartProducts'));
  }
  getboxesFromCart() {
    if (localStorage.getItem('cartboxes') == null) {
      return [];
    }
    else
      return JSON.parse(localStorage.getItem('cartboxes'));
  } 
  getBoxFromCart(Id) {
    //debugger;
    if (localStorage.getItem('cartboxes') == null) {
      return {};
    }
    else{
      var boxes = JSON.parse(localStorage.getItem('cartboxes'));
      var i = boxes.findIndex(x=>x.id == Id);
      if(i > -1){
        return boxes[i];
      }
      return {};

    }
  } 
  addProductToCart(products: any) {
     
    let count = 0;
    this.cartProducts.forEach(element => {
      if (products.productId == element.productId) {
        count++;
      }
    });
    if (count == 0) {
      this.cartProducts.push(products);
      this.toast("Product added to cart successfully")
      this.updateCartProduct()
     
    }
    else {
      this.toast("Product already in cart")
    } 
    if(this.sessionService.loggedIn == true){
      this.wishListService.InsertList(this.getProductFromWishlist())
      .subscribe(result => {
      });
      this.cartService.InsertList(this.getProductFromCart())
      .subscribe(result => {
      });
    }   
  } 
  addboxToCart(boxes: any) {
     
    let count = 0;
    this.cartBoxes.forEach(element => {
      if (boxes.boxId == element.boxId) {
        count++;
      }
    });
    if (count == 0) {
      this.cartBoxes.push(boxes);
      this.toast("Box added to cart successfully")
      this.updateCartBoxes()
     
    }
    else {
      this.toast("Box already in cart")
    } 
    if(this.sessionService.loggedIn == true){
      this.cartService.InsertList(this.getboxesFromCart())
      .subscribe(result => {
      });
    }   
  } 
  updateboxToCart(boxes: any) {
     //debugger;
    let count = 0;
    this.cartBoxes.forEach(element => {
      if (boxes.boxId == element.boxId) {
        count++;
      }
    });
    if (count == 0) {
     
      this.cartBoxes.push(boxes);
      this.toast("Box added to cart successfully")
      this.updateCartBoxes()
     
    }
    else {
      var i = this.cartBoxes.findIndex(x=>x.id == boxes.id);
      if(i > -1){
   
        this.cartBoxes[i] = boxes;
        this.updateCartBoxes()
  

    }
    } 
    if(this.sessionService.loggedIn == true){
      this.cartService.InsertList(this.getboxesFromCart())
      .subscribe(result => {
      });
    }   
  } 
  addProductToCartWithoutToast(products: any) {
     
    let count = 0;
    this.cartProducts.forEach(element => {
      if (products.productId == element.productId) {
        count++;
      }
      if(products.id != element.id && products.productId == element.productId){
        element.id = products.id;
       this.updateCartProduct()
      }

    });
    if (count == 0) {
      this.cartProducts.push(products);
      this.updateCartProduct()
    
    }
    else {
    } 
    
  }
  addBoxToCartWithoutToast(boxes: any) {
     
    let count = 0;
    this.cartBoxes.forEach(element => {
      if (boxes.boxId == element.boxId) {
        count++;
      }
      if(boxes.id != element.id && boxes.boxId == element.boxId){
        element.id = boxes.id;
       this.updateCartBoxes()
      }

    });
    if (count == 0) {
      this.cartBoxes.push(boxes);
      this.updateCartBoxes()
    
    }
    else {
    } 
    
  }
  updateCartInAPIs():Observable<boolean>{
    //debugger;
    var res=false;
    if(this.sessionService.loggedIn == true){
      // this.wishListService.InsertList(this.getProductFromWishlist())
      // .subscribe(result => {
      // });
      // var productInLocal = this.getProductFromCart();
      this.cartProducts.forEach(element => {
        var pro = new CartModel();
        pro.quantity = element.quantity;
        pro.totalPrice = element.totalPrice;
        pro.productId = element.productId;
        pro.imageUrl = element.imageUrl;
        pro.unitPrice = element.totalPrice;
        if(element.productUnits && element.productUnits.length > 0){
          var i = element.productUnits.findIndex(x=>x.price == element.totalPrice);
          if(i>-1){
            pro.userId = element.productUnits[i].id;
          }
          else{
            pro.userId = null;
          }
        //   this.cartService.createCart(pro)
        // .subscribe(result => {
        // });
        } 
        this.cards.push(pro);
      });
      if(this.cards && this.cards.length > 0){
        return this.cartService.InsertList(this.cards)
      }
    }   
  }
  updateBoxCartInAPIs():Observable<boolean>{
    //debugger;
    var res=false;
    if(this.sessionService.loggedIn == true){
      this.cartBoxes.forEach(element => {
        var pro = new BoxCartModel();
        pro.quantity = element.quantity;
        pro.totalPrice = element.totalPrice;
        pro.boxId = element.boxId;
        pro.imageUrl = element.imageUrl;
        pro.amount = element.amount;
        pro.avalable = true;
        pro.boxPrice = element.boxPrice;
        pro.count = element.count;
        pro.id = element.id;
        pro.products = element.products;
        this.boxes.push(pro);
      });
      if(this.boxes && this.boxes.length > 0){
        return this.boxCartService.InsertList(this.boxes)
      }
    }   
  }
  updateCartInAPIsAsAGuest():Observable<boolean>{
      this.cartProducts.forEach(element => {
        var pro = new CartModel();
        pro.quantity = element.quantity;
        pro.totalPrice = element.totalPrice;
        pro.productId = element.productId;
        pro.imageUrl = element.imageUrl;
        pro.unitPrice = element.totalPrice;
        if(element.productUnits && element.productUnits.length > 0){
          var i = element.productUnits.findIndex(x=>x.price == element.totalPrice);
          if(i>-1){
            pro.producUnitId = element.productUnits[i].id;
          }
          else{
            pro.producUnitId = null;
          }
        //   this.cartService.createCart(pro)
        // .subscribe(result => {
        // });
        } 
        this.cards.push(pro);
      });
      if(this.cards && this.cards.length > 0){
        return this.cartService.InsertListAsAGuest(this.cards)
      } 
  }
  updateBoxCartInAPIsAsAGuest():Observable<boolean>{
      this.cartBoxes.forEach(element => {
        var pro = new BoxCartModel();
        pro.quantity = element.quantity;
        pro.totalPrice = element.totalPrice;
        pro.boxId = element.boxId;
        pro.imageUrl = element.imageUrl;
        pro.amount = element.amount;
        pro.avalable = true;
        pro.boxPrice = element.boxPrice;
        pro.count = element.count;
        pro.id = element.id;
        pro.products = element.products;
        this.boxes.push(pro);
      });
      if(this.boxes && this.boxes.length > 0){
        return this.boxCartService.InsertListAsAGuest(this.boxes)
      } 
  }
  updateCartProduct(){
    localStorage.setItem("cartProducts", JSON.stringify(this.cartProducts));
  }
  updateCartBoxes(){
    localStorage.setItem("cartboxes", JSON.stringify(this.cartBoxes));
  }
  removeProductFromCart(products: any) {
    //return  localStorage.removeItem('cartProducts');
    let count = 0;
    this.cartProducts.forEach((element) => {
      if (products.productId == element.productId) {
        var i = this.cartProducts.findIndex(x=>x.productId == element.productId);
        this.cartProducts.splice(i, 1);
        count++;
      }
    });
    
    if (count != 0) {
      this.toast("Product remove from cart")
      this.updateCartProduct()
    }
  }
  removeBoxFromCart(products: any) {
    //return  localStorage.removeItem('cartProducts');
    let count = 0;
    this.cartBoxes.forEach((element) => {
      if (products.boxId == element.boxId) {
        var i = this.cartBoxes.findIndex(x=>x.boxId == element.boxId);
        this.cartBoxes.splice(i, 1);
        count++;
      }
    });
    
    if (count != 0) {
      this.toast("Product remove from cart")
      this.updateCartBoxes()
    }
  }
  getProductFromWishlist() {
    if (localStorage.getItem('wishlistProducts') == null) {
      return [];
    }
    else
      return JSON.parse(localStorage.getItem('wishlistProducts'));
  }
  getProductGiftCards() {
    if (localStorage.getItem('giftCards') == null) {
      return [];
    }
    else
      return JSON.parse(localStorage.getItem('giftCards'));
  }
  addProductToWishlist(products: any){
    let count = 0;
    this.wishlistProducts.forEach(element => {
      if (products.productId == element.productId) {
        count++;
      }
    });
    if (count == 0) {
      this.wishlistProducts.push(products);
      this.toast("Product added to wishlist successfully")
      this.updateWishlistProduct()
    }
    else {
      this.toast("Product already in wishist")
    }
  }
  addProductToWishlistWithoutToast(products: any){
    let count = 0;
    this.wishlistProducts.forEach(element => {
      if (products.productId == element.productId) {
        count++;
      }
      if(products.id != element.id && products.productId == element.productId){
        element.id = products.id;
       this.updateWishlistProduct()
      }
    });
    if (count == 0) {
      this.wishlistProducts.push(products);
      this.updateWishlistProduct()
    }
    else {
    }
  }
  addProductToGiftCardsToast(cards: any){
    let count = 0;
    this.wishlistProducts.forEach(element => {
      if (cards.id == element.id) {
        count++;
      }
   
    });
    if (count == 0) {
      this.giftCards.push(cards);
      this.updateGiftCards()
    }
    else {
    }
  }
  updateWishlistProduct(){
    localStorage.setItem("wishlistProducts", JSON.stringify(this.wishlistProducts));
  }
  updateGiftCards(){
    localStorage.setItem("giftCards", JSON.stringify(this.giftCards));
  }
  removeProductFromWishlist(products){
    let count = 0;
    this.wishlistProducts.forEach((element) => {
      if (products.productId == element.productId) {
        this.wishlistProducts.splice(0, 1);
        count++;
      }
    });
    if (count != 0) {
      this.toast("Product remove from wishist")
      this.updateWishlistProduct()
    }
  }
  onAddQuantity(product) {
    this.cartProducts.forEach((element, index) => {
      if (product.productId == element.productId) {
        element.quantity++;
      }
    });
    this.updateCartProduct()

    this.wishlistProducts.forEach((element, index) => {
      if (product.productId == element.productId) {
        element.quantity++;
      }
    });
    
    this.updateWishlistProduct()
  }
  onAddBoxQuantity(product) {
    this.cartBoxes.forEach((element, index) => {
      if (product.boxId == element.boxId) {
        element.quantity++;
      }
    });
    this.updateCartBoxes()
  }
  savePrice(product) {
    this.cartProducts.forEach((element, index) => {
      if (product.productId == element.productId) {
        element.totalPrice = product.totalPrice;
      }
    });
    this.updateCartProduct()

    this.wishlistProducts.forEach((element, index) => {
      if (product.productId == element.productId) {
         element.totalPrice = product.totalPrice;
      }
    });
    
    this.updateWishlistProduct()
  }
  onRemoveQuantity(product) {
    this.cartProducts.forEach((element, index) => {
      if (product.productId == element.productId) {
        element.quantity--;
      }
    });
    this.updateCartProduct()

    this.wishlistProducts.forEach((element, index) => {
      if (product.productId == element.productId) {
        element.quantity--;
      }
    });
    
    this.updateWishlistProduct()
  }
  onRemoveBoxQuantity(product) {
    this.cartBoxes.forEach((element, index) => {
      if (product.boxId == element.boxId) {
        element.quantity--;
      }
    });
    this.updateCartBoxes()
  }
  toast(msg){
    this.toastr.success(msg);
  }
  
}
