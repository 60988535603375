import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { checkSoudi, emailValidator, matchingPasswords } from 'src/app/core/utils/app-validators';
import { CityService } from 'src/app/pages/session/city.service';
import { CityModel } from 'src/app/pages/session/model/city.model';
import { RegionModel } from 'src/app/pages/session/model/restion.model';
import { UserRegistration } from 'src/app/pages/session/model/user-registration';
import { SessionService } from 'src/app/pages/session/session.service';
import { ConfirmCodeModalComponent } from '../confirm-code-modal/confirm-code-modal.component';

@Component({
    selector: 'registration-modal',
    templateUrl: 'registration-modal.component.html',
    styleUrls: ['registration-modal.component.scss']
})
export class RegistrationModalComponent implements OnInit {
    public current_language;
    registerForm: FormGroup;
    Code = 0;
    cities: CityModel[] = [];
    cityId = 0;
    resions: RegionModel[] = [];
    user: UserRegistration = {};
    openForm = false;
    constructor(
        private fb: FormBuilder,
        private UserService: SessionService,
        public toaster: ToastrService,
        private router: Router ,
        private matDialog : MatDialog,
        private cityService: CityService,
        public dialogRef : MatDialogRef<RegistrationModalComponent>
        
        ) {
      this.current_language = localStorage.getItem('lang');

     }
     signUp(value) {
        value.userName = value.email;
        this.user = value;
        this.user.isSaudi = false;
        this.UserService.register(value)
        .subscribe(data => {
          if (data === 'Phone Used' || data === 'Email Used') {
              this.toaster.warning('You already have an account');
            }
            else{
              localStorage.setItem('token', data.token);
              localStorage.setItem('currentUser', JSON.stringify(data));
              this.router.navigate(['/checkout']);
              this.UserService.loggedIn = true;
              this.dialogRef.close()
            }
         },
           error => {
              this.toaster.error('This is not good!', 'You already have an account');
            });
     }
      ngOnInit() {
         
        this.cityService.getAll(0)
        .subscribe(data => {
          this.cities = data;
        });
        this.user = new UserRegistration();
        this.registerForm = this.createForm();
        // this.registerForm = this.formBuilder.group({
        //   name : [this.user.name, Validators.compose([Validators.required, Validators.minLength(3)])],
        //   email : [this.user.email , Validators.compose([Validators.required, emailValidator])],
        //   phone : [this.user.phone , Validators.required],
        //    password : [this.user.password , Validators.required],
        //   confirmPassword: [this.user.confirmPassword , Validators.required],
        //   reagionId : [this.user.reagionId , Validators.required],
        //   gender : [this.user.gender , Validators.required],
        //   address : [this.user.address , Validators.required],
        //   cityId : [this.user.cityId]
        // }, {validator: matchingPasswords('password', 'confirmPassword')});
    
      }
    
      createForm():FormGroup{
        this.openForm = true;
        return this.fb.group({
          name : [this.user.name, Validators.compose([Validators.required, Validators.minLength(3)])],
          email : [this.user.email , Validators.compose([Validators.required, emailValidator])],
          phone : [this.user.phone ],
           password : [this.user.password , Validators.compose([Validators.required,  Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')])],
          confirmPassword: [this.user.confirmPassword , Validators.required],
          reagionId : [this.user.reagionId , Validators.required],
          gender : [this.user.gender , Validators.required],
          address : [this.user.address , Validators.required],
          cityId : [this.user.cityId, Validators.required],
          isSaudi:[this.user.isSaudi]
        }, {validator: [matchingPasswords('password', 'confirmPassword')]});
        
     }
     changeCity(value) {
       this.cityService.GetResionsByCityId(value.cityId)
       .subscribe(data => {
         this.resions = data;
       });
     }
     IsSoudi = false;
     setNotSoudi($event){
        
       this.IsSoudi =false;
       checkSoudi('isSaudi','phone');
     }
  }
  