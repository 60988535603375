import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GiftCardModel } from './models/gift-card.model';
import { GiftCardWithoutDetailsModel } from './models/gift-card-without-details.model';
@Injectable({
  providedIn: 'root'
})
export class GiftCardService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }

  getAll(page:number): Observable<GiftCardWithoutDetailsModel[]> {
 
    return this.http
      .get<GiftCardWithoutDetailsModel[]>(`${this.baseUrl}/GiftCard/GetAll/${page}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetAllForUser(page:number): Observable<GiftCardModel[]> {
    return this.http
      .get<GiftCardModel[]>(`${this.baseUrl}/GiftCard/GetAllForUser/${page}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  
  GetCount(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/GiftCard/GetCount`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  createGiftCard(payload: GiftCardModel): Observable<boolean> {
    return this.http
      .post<boolean>(`${this.baseUrl}/GiftCard/Insert`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  put(payload: GiftCardModel): Observable<boolean> {
    return this.http
      .put<boolean>(`${this.baseUrl}/GiftCard/Update`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  delete(payload: GiftCardModel): Observable<boolean> {
    return this.http
      .delete<boolean>(`${this.baseUrl}/GiftCard/Delete/${payload.id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  
  GetById(id: number): Observable<GiftCardModel> {
      return this.http
      .get<GiftCardModel>(`${this.baseUrl}/GiftCard/GetById/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
     }
 
}
