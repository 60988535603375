import { Component, OnInit } from '@angular/core';
import { PurchaseCouponModel } from '../../models/purchase-coupon.model';
import { PurchaseCouponService } from '../../services/purchase-coupon.service';
import { CreatePurchaseCouponComponent } from '../create-purchase-coupon/create-purchase-coupon.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
    // moduleId: module.id,
    selector: 'all-purchase-coupons',
    templateUrl: 'all-purchase-coupons.component.html',
    styleUrls: ['all-purchase-coupons.component.scss']
})
export class AllPurchaseCouponsComponent implements OnInit {
    coupons: PurchaseCouponModel [] = [] ;
    coupon: PurchaseCouponModel = {};
    Form: FormGroup;
    openForm = false;
    isCollapsed = false;
    constructor(
        public toaster: ToastrService,
        private puraseCouponService: PurchaseCouponService ,
        private fb: FormBuilder,
        private modalService: NgbModal) {}
    ngOnInit(): void {
        this.puraseCouponService.GetForCurrentUser()
        .subscribe(data => {
            this.coupons = data;
        });
    }
    openModal(): void {
        // this.modalService.open(CreatePurchaseCouponComponent);
        this.isCollapsed = !this.isCollapsed;
        this.coupon = new PurchaseCouponModel();
        this.Form = this.createForm();
      }
      createForm(): FormGroup {
          this.openForm = true;
          return this.fb.group({
          id: [this.coupon.id],
          amount: [this.coupon.amount, Validators.required],
          code: [this.coupon.code],
          isUsed: [this.coupon.isUsed],
          paymentType: [this.coupon.paymentType],
          usedBy: [this.coupon.usedBy],
          usedDate: [this.coupon.usedDate],
        });
      }
      onSubmit() {
        this.coupon = this.Form.getRawValue();
        this.puraseCouponService.createPurchaseCoupon(this.coupon)
        .subscribe(data => {
          if ( data === true ) {
              this.toaster.success('Add Done Success');
              this.ngOnInit();
              this.isCollapsed = !this.isCollapsed;
          }
        });
      }
}
