import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-log-in-one',
  templateUrl: './log-in-one.component.html',
  styleUrls: ['./log-in-one.component.scss']
})
export class LogInOneComponent implements OnInit {
public current_language;
  constructor(
    public translate: TranslateService,
    private UserService: SessionService,
    public toaster: ToastrService,
    private router: Router  ) { 
    this.current_language = localStorage.getItem('lang');

    }

  ngOnInit() {

  }
  login(value) {
    this.UserService.login(value)
    .subscribe(data => {
       if (data === true) {
          this.router.navigate(['']);
          if(this.current_language != 'ar'){
            this.toaster.success('Welcome');
          }
          else{
            this.toaster.success('مرحبا بك');
          }
       }},
       error => {
         if(this.current_language != 'ar'){
           this.toaster.error('This is not good!', 'Email or Password is not true');
         }
         else{
          this.toaster.error('الرجاء المحاولة مرة اخري', 'البريد الالكتروني او كلمة المرور غير صحيحية');
         }
        });
 }

}
