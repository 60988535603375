import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { TranslateModule } from '@ngx-translate/core';

import { ComponentsModule } from 'src/app/components/components.module';
import { HeaderModule } from 'src/app/layouts/header/header.module';
import { FooterModule } from 'src/app/layouts/footer/footer.module';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { StoreLocationsComponent } from './store-locations.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SafePipeModule } from 'safe-pipe';
@NgModule({
  declarations: [StoreLocationsComponent],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule,
    ComponentsModule,
    SafePipeModule ,
    // Specify your library as an import
    SlickCarouselModule,
    HeaderModule,
    FooterModule,
  ],
  exports: [StoreLocationsComponent]
})
export class StoreLocationsModule { }
