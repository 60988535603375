export class ShippingAdderssModel{
    shippingAddressFirstName?: string;
    shippingAddressLastName?: string;
    shippingAddressCompanyName?: string;
    shippingAddressAddress?: string;
    shippingAddressCityId?: number;
    shippingAddressRegionId?: number;
    shippingAddressCityName?: string;
    shippingAddressRegionName?: string;
    shippingAddressCity?: string;
    shippingAddressPhone?: string;
    constructor(shipping?: ShippingAdderssModel){
        shipping = shipping ? shipping : {};
        this.shippingAddressFirstName   = shipping.shippingAddressFirstName   ? shipping.shippingAddressFirstName   : '';
        this.shippingAddressLastName    = shipping.shippingAddressLastName    ? shipping.shippingAddressLastName    : '';
        this.shippingAddressCompanyName = shipping.shippingAddressCompanyName ? shipping.shippingAddressCompanyName : '';
        this.shippingAddressAddress     = shipping.shippingAddressAddress     ? shipping.shippingAddressAddress     : '';
        this.shippingAddressCityId      = shipping.shippingAddressCityId      ? shipping.shippingAddressCityId      : 0;
        this.shippingAddressRegionId    = shipping.shippingAddressRegionId    ? shipping.shippingAddressRegionId    : 0;
        this.shippingAddressCityName    = shipping.shippingAddressCityName    ? shipping.shippingAddressCityName    : '';
        this.shippingAddressRegionName  = shipping.shippingAddressRegionName  ? shipping.shippingAddressRegionName  : '';
        this.shippingAddressCity        = shipping.shippingAddressCity        ? shipping.shippingAddressCity        : '';
        this.shippingAddressPhone       = shipping.shippingAddressPhone       ? shipping.shippingAddressPhone       : '';
    }
}