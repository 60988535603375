import { Component, OnInit } from '@angular/core';
import { UpToMessageModel } from './up-to-message.model';
import { UpToMessageService } from './up-to-message.service';

@Component({
  selector: 'app-alert-header',
  templateUrl: './alert-header.component.html',
  styleUrls: ['./alert-header.component.scss']
})
export class AlertHeaderComponent implements OnInit {

  constructor(private upToMessageService: UpToMessageService) {
    this.current_language = localStorage.getItem('lang');

   }
  upTo: UpToMessageModel = {};
  open = false;
  public current_language;
  ngOnInit() {
    this.upToMessageService.getMessage()
    .subscribe(data => {
      this.upTo = data;
      this.open = true;
    })
  }

}
