import { GiftMessageDesignCategoryImagesModel } from "./gift-message-design-category-images.model";

export class CarouselModel{
    id?:number;
    images?:GiftMessageDesignCategoryImagesModel[];
    constructor(item?:CarouselModel){
        item = item ? item : {};
        this.id = item.id ? item.id : 0;
        this.images = item.images ? item.images : [];
    }
}