import { KatoraService } from 'src/app/services/katora.service';
import { GiftCardService } from './../../gift-card.service';
import { GiftCardModel } from './../../models/gift-card.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SliderInfoService } from 'src/app/pages/home/slider-info.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

@Component({
    selector: 'gift-cards',
    templateUrl: 'gift-cards.component.html',
    styleUrls: ['gift-cards.component.scss']
})
export class GiftCardsComponent implements OnInit {
    Cards : GiftCardModel[] = [];
    Card : GiftCardModel = {};
  public current_language;
  openView = false;
  @ViewChild('slickModal', {static: false}) slickModal: SlickCarouselComponent;

    constructor(private giftService: GiftCardService,
      private sliderInfoService: SliderInfoService,
      private sanitization: DomSanitizer,
      private katoraService:KatoraService
      ){
      this.current_language = localStorage.getItem('lang');
      }
      slideConfigCarousel = {
        slidesToShow : 1,
        slidesToScroll: 1,
        dots: false,
        infinite: true,
        arrows : true,
        autoplay: true,
        autoplaySpeed: 700,
        speed: 500,
        pauseOnHover: false,
        pauseOnDotsHover: true,
        responsive: [
          {
          breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
          breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    
      };
      slideConfigCarousel2 = {
        slidesToShow : 1,
        slidesToScroll: 1,
        dots: false,
        infinite: true,
        arrows : true,
        autoplay: true,
        autoplaySpeed: 1000,
        speed: 600,
        pauseOnHover: false,
        pauseOnDotsHover: true,
        responsive: [
          {
          breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
          breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    
      };
      ngOnInit() {
        this.giftService.GetAllForUser(0)
        .subscribe(data => {
            this.Cards = data;
        });
      }
      saveImage(item){
        return this.sanitization.bypassSecurityTrustStyle(`url(${item})`);
      }
      View(Card : GiftCardModel) {
 
        this.Card = new GiftCardModel();
        this.Card = Card;
        this.openView = true;
      }
      chooseGift(Card){
        this.katoraService.addProductToGiftCardsToast(Card);
      }
      next() {
        this.slickModal.slickNext();
      }
      
      prev() {
        this.slickModal.slickPrev();
      }
}
