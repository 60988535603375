import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomListModel } from '../models/custom-list.modle';
import { ProductModel } from '../pages/shop/models/Product.model';

@Injectable({
  providedIn: 'root'
})
export class CustomListService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {
  }
  getAll(page:number): Observable<CustomListModel[]> {
    return this.http
      .get<CustomListModel[]>(`${this.baseUrl}/CustomList/GetAll/${page}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  getByListId(id:number): Observable<ProductModel[]> {
    return this.http
      .get<ProductModel[]>(`${this.baseUrl}/ProductCustomList/GetByListIdForUser/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
}
