import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ExecutePaymentRequestModel } from './models/my-fatoorah/ExecutePaymentRequest.model';
import { InitiateModel } from './models/my-fatoorah/Initiate.model';

@Injectable({
  providedIn: 'root'
})
export class MyFatoorahService {
  baseUrl = environment.DevApiUrl;
  //  token = '8dgvs9b9_EMaB-xUED-vSnI3vgUIGKDUQ9kRBcHwGsAmCE1w_3rR-kxYBq5WwE3YjCsFjAGpX1k-RIdupztco1DlRcI89oEKEzM6EvkqwKZMJWjo7f7HiTKUju0Yuymt6y3lEMs6vQO-77k3eF16Uqc_5zXvIOCKp2FsKDIOO13dSOBjXLi8XevU1_M4u5ZuJXGpriFkb7cl8T13O9hg1iCbJATXukUzyq1ZjWQC5cR8cGzu7-fcqU69WMpEYkxNXujg19pNv5ETDEZEeMVEeG6DKC6_wKCzzW3WN5yrricc6kkZQTpcWmVfHCDSnegDGI5FsdJsgwxVTZMS_tJT3gO0iyafM6s6OpHODYKVO5gJqmx1uPOY1TDoySH8J_slDfkUWhX3z2jt8fm2k9DTXmrL0qIVpN7BbHkqKTM1WLrVjmyzpblujIF8XPIcBZxqOc_7sARXWr43WKibzdn1i6py9L2aV2MavXMOrdsZtxvkvdqQlMhw5DRsTNSNnnNJVk_mrGbza8uMk8EhQnVRmQCFsO6I70YkKvCatRudlpKLgXgqT3hyiD4FUHReBFTZa9I6fxw2owu8iyIq8lizaPbxAfBpvu2K4_MevbGXlKrqg5RvNTRdhSPNTXrcNciuMKAqUVzhPSTc1hy5YfnbkLpKe4BfBXtKZSmO4YUdReQcJLNJbNl9_46DPorWlFt8R5NgRQ';
    //for Test
  token = 'rLtt6JWvbUHDDhsZnfpAhpYk4dxYDQkbcPTyGaKp2TYqQgG7FGZ5Th_WD53Oq8Ebz6A53njUoo1w3pjU1D4vs_ZMqFiz_j0urb_BH9Oq9VZoKFoJEDAbRZepGcQanImyYrry7Kt6MnMdgfG5jn4HngWoRdKduNNyP4kzcp3mRv7x00ahkm9LAK7ZRieg7k1PDAnBIOG3EyVSJ5kK4WLMvYr7sCwHbHcu4A5WwelxYK0GMJy37bNAarSJDFQsJ2ZvJjvMDmfWwDVFEVe_5tOomfVNt6bOg9mexbGjMrnHBnKnZR1vQbBtQieDlQepzTZMuQrSuKn-t5XZM7V6fCW7oP-uXGX-sMOajeX65JOf6XVpk29DP6ro8WTAflCDANC193yof8-f5_EYY-3hXhJj7RBXmizDpneEQDSaSz5sFk0sV5qPcARJ9zGG73vuGFyenjPPmtDtXtpx35A-BVcOSBYVIWe9kndG3nclfefjKEuZ3m4jL9Gg1h2JBvmXSMYiZtp9MR5I6pvbvylU_PP5xJFSjVTIz7IQSjcVGO41npnwIxRXNRxFOdIUHn0tjQ-7LwvEcTXyPsHXcMD8WtgBh-wxR8aKX7WPSsT1O8d8reb2aR7K3rkV3K82K_0OgawImEpwSvp9MNKynEAJQS6ZHe_J_l77652xwPNxMRTMASk1ZsJL'
   httpOptions = {
    headers: new HttpHeaders({
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"

    })
  };
  constructor(private http: HttpClient) {
  }

  MyFatoorahPay(item?:ExecutePaymentRequestModel): Observable<any> {
    //debugger;
    return this.http
      .post<any>(`${this.baseUrl}/MyFatoorahPayment/ExecutePayment`,item, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  InitiatePayment(item:number): Observable<any> {
    //debugger;
    return this.http
      .post<any>(`${this.baseUrl}/MyFatoorahPayment/InitiatePayment`,item, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCountries():Observable<any>{
    return this.http
    .get<any>(`https://api-sa.myfatoorah.com/v2/GetCountries`, this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
 
}
