import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserModel } from '../models/user.model';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  type = 1;
  user: UserModel = {};
  password: string;
  constructor(private userService: UserService , public toaster:ToastrService) { }

  ngOnInit() {
    this.userService.GetCurrentUser()
    .subscribe(data => {
      this.user = data;
      if ( data.id == 0 ) {
        this.type = 1;
        this.user = new UserModel();
      }
      else {
        this.type = 2;
        this.user = data;
        this.user.confirmPassword = "";
        this.password = this.user.password;
      }
    });
  }
  onSubmit(){
    if(this.user.confirmPassword == this.user.password){
      this.userService.createUser(this.user)
      .subscribe(data => {
        this.ngOnInit();
      });
    }
    else{
      this.toaster.error("Please Enter Your Valid Password")
    }
 
  }

}
