import { Component, OnInit } from '@angular/core';
import { KatoraService } from 'src/app/services/katora.service';
import { Lightbox } from 'ngx-lightbox';
import { ProductWithDetailsModel } from '../../shop/models/product-with-details.model';
import { ProductModel } from '../../shop/models/Product.model';
import { ProductService } from '../../shop/product.service';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from '../../session/session.service';

@Component({
  selector: 'app-product-two',
  templateUrl: './product-two.component.html',
  styleUrls: ['./product-two.component.scss']
})
export class ProductTwoComponent implements OnInit {

  public productAddedToCart;
  public productTotal;
  Id = 0;
  CityId='0';
  product: ProductWithDetailsModel = {};
  products: ProductModel[] = [];
  slideProductConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    asNavFor: '.thumbs',
  };

  slideProductNavConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".feedback",
    centerMode: true,
    centerPadding: '0px',
    dots: true,
    arrows: false,
    focusOnSelect: true
  };

  slideRelatedProductConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    infinite: false,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };
  constructor(
    private katoraService: KatoraService,
    private _lightbox: Lightbox,
    private productService: ProductService,
    private route: ActivatedRoute,
    private sessionService:SessionService
    ) {
    // this.loadAlbum();
    this.Id = + route.snapshot.paramMap.get('Id');
  }

  private _album = [];
  open(index: number): void {

    // open lightbox
    this._lightbox.open(this._album, index);
  }
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  onAddCart(product: any) {
    this.katoraService.addProductToCart(product);
  }

  onAddWishlist(product: any){
    this.katoraService.addProductToWishlist(product);
  }
  ngOnInit() {
    if(this.sessionService.loggedIn == true){
      this.CityId = '0';
    }
    else{
      this.CityId = localStorage.getItem('CityId');
    }
    this.productService.GetById(this.Id)
    .subscribe(data => {
      this.product = data;
      this.product.amount = 1;
      this.productService.GetProductsForUser(this.product.categoryId, 1)
      .subscribe(prodects => {
        this.products = prodects;
      })
    })
  }
  ngAfterContentChecked() {
    this.productAddedToCart = this.katoraService.getProductFromCart();
    this.productTotal = this.katoraService.calculateTotal().toFixed(2);
  }
  addQuantity(product){
    this.product.amount ++;
  }
  removeQuantity(product: any){
    if(this.product.amount > 0){
      this.product.amount --;
    }
  }

  slickInit(e) {
  }
}
