import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from 'src/app/services/category.service';
import { UserRegistration } from '../../session/model/user-registration';
import { SessionService } from '../../session/session.service';
import { CategoryModel } from '../models/category.model';
import { ProductModel } from '../models/Product.model';
import { SearchModel } from '../models/search.model';
import { ProductService } from '../product.service';

@Component({
    // moduleId: module.id,
    selector: 'shop-search',
    templateUrl: 'shop-search.component.html',
    styleUrls: ['shop-search.component.scss']
})
export class ShopSearchComponent implements OnInit {
    @ViewChild('swap',{static:false}) swap: ElementRef;
    products: ProductModel[] = [];
    AllProducts: ProductModel[] = [];
    categories: CategoryModel[] = [];
    count = 0;
    currenPage = 1;
    pages: number [] = [] ;
    model: SearchModel = {};
    name = '';
    isIn:boolean=false;
    user: UserRegistration = {};
    CategoryId  = 0;
    CategoryIdForSearch  = 0;
    searchName: '';
    CategoryName: string = 'All Categories';
    filteredArray: any[] = []

  public current_language;
  Form: FormGroup;
    constructor(
      private productService: ProductService,
      private route: ActivatedRoute,
      private categoryService: CategoryService,
      private fb: FormBuilder,
      private sessionService:SessionService
    
    ) { 
      this.CategoryIdForSearch = + route.snapshot.paramMap.get('CategoryId');
      this.currenPage = + route.snapshot.paramMap.get('page');
    this.current_language = localStorage.getItem('lang');
    this.name = route.snapshot.paramMap.get('name');
    }
    display="grid_4column";
    changeDisplay(display){
      this.display = display;
    }
    CityId = '0';
    ngOnInit() {
       
      if(this.sessionService.loggedIn == true){
        this.CityId = '0';
      }
      else{
        this.CityId = localStorage.getItem('CityId');
      }
      this.model = new SearchModel();
      this.model.name = this.name;
      this.model.categoryId = this.CategoryIdForSearch;
      // this.productService.GetCountByCategoryId(this.CategoryIdForSearch,+this.CityId)
      // .subscribe(count => {
      //   this.count = count;
      // });
      this.productService.GetProductsForSearch(this.model, 1)
      .subscribe(data => {
        this.products = data;
        this.filteredArray = [];
        this.filteredArray = this.products.slice(0, 8);
        // this.products = Array(this.products.length).fill(0).map((x, i) => (new ProductModel({amount:x.amount,categoryNameAr : x.categoryNameAr,categoryNameEn:x.categoryNameEn,imageUrl:x.imageUrl,images:x.images,price:x.price,productId:x.productId,productNameAr:x.productNameAr,productNameEn:x.productNameEn,productUnits:x.productUnits,quantity:x.quantity,smallDescriptionAr:x.smallDescriptionAr,smallDescriptionEn:x.smallDescriptionEn})));
        this.count = this.products.length;
        let length = this.count / 8;
        if(length % 8){
          length ++;
        }
        for (let index = 1; index <=  length ; index ++) {
          this.pages.push(index);
         }
        this.products.forEach(element => {
          element.amount = 1;
        });
      });
      this.categoryService.GetAllForDropdownMenu()
      .subscribe(data => {
        this.categories = data;
        this.categories.forEach(element => {
          if(element.hideFromDropdownMenu == true){
            var i = this.categories.findIndex(x=>x.id == element.id);
            if(i > -1){
              this.categories.splice(i,1);
            }
          }
        });
        this.categories.splice( 0 , 0 , {id: 0, nameEn: 'All Categories',nameAr:'كل الفئات'});
      });
      this.model = new SearchModel();
      this.Form = this.createForm();
      const token  = localStorage.getItem('token');
      if (token != null || token != '') {
        this.user = JSON.parse(window.atob(token.split('.')[1]));
        this.isIn = true;
        this.name = this.user.userName
      }
      else{
        this.name = '';
      }
    }
    getByCategoryId() {
      this.productService.GetProductsForUser(this.CategoryId, this.currenPage)
      .subscribe(data => {
        this.products = data;
        this.filteredArray = [];
        this.filteredArray = this.products.slice(0, 8);
        // this.products = Array(this.products.length).fill(0).map((x, i) => (new ProductModel({amount:x.amount,categoryNameAr : x.categoryNameAr,categoryNameEn:x.categoryNameEn,imageUrl:x.imageUrl,images:x.images,price:x.price,productId:x.productId,productNameAr:x.productNameAr,productNameEn:x.productNameEn,productUnits:x.productUnits,quantity:x.quantity,smallDescriptionAr:x.smallDescriptionAr,smallDescriptionEn:x.smallDescriptionEn})));
        this.count = this.products.length;
        let length = this.count / 8;
        if(length % 8){
          length ++;
        }
        for (let index = 1; index <=  length ; index ++) {
          this.pages.push(index);
         }
        this.products.forEach(element => {
          element.amount = 1;
        });
      });
    }
    changePage(page: number) {
      this.currenPage = page;
      var start = page*8;
      this.filteredArray = this.products.slice(start-8 , start)
      // this.currenPage = page;
      // this.productService.GetProductsForUser(this.CategoryId, this.currenPage,+this.CityId)
      // .subscribe(data => {
      //   this.products = data;
      // });
    }
    incrementPage() {
      if(this.currenPage < this.pages.length) {
        this.currenPage ++ ;
        var start = this.currenPage*8;
        this.filteredArray = this.products.slice(start-8 , start)
        // this.currenPage ++ ;
        // this.productService.GetProductsForUser(this.CategoryId, this.currenPage,+this.CityId)
        // .subscribe(data => {
        //   this.products = data;
        // });
      }
    }
    decrementPage() {
    if(this.currenPage > 1) {
      this.currenPage -- ;
      var start = this.currenPage*8;
      this.filteredArray = this.products.slice(start-8 , start)
      // this.currenPage -- ;
      // this.productService.GetProductsForUser(this.CategoryId, this.currenPage,+this.CityId)
      // .subscribe(data => {
      //   this.products = data;
      // });
    }
    }
    createForm(): FormGroup {
      return this.fb.group({
        categoryId: [this.CategoryIdForSearch],
        searchName: [this.searchName]
      });
    }
    setCategoryId(categoryId: number , CategoryName: string){
      this.CategoryIdForSearch = categoryId;
      this.CategoryName = CategoryName;
    }
    search() {
      this.products = [];
      const item = this.Form.getRawValue();
      if (item.searchName === '') {
        item.searchName = ' ';
      }
      this.model = new SearchModel();
      this.model.categoryId = this.CategoryIdForSearch;
      this.model.name = item.searchName;
      this.productService.GetProductsForSearch(this.model, 1)
      .subscribe(data => {
        this.products = data;
        let length = this.products.length / 8;
        if (length % 8) {
          length ++;
        }
        for (let index = 1; index <=  length ; index ++) {
          this.pages.push(index);
         }
        this.products.forEach(element => {
          element.amount = 1;
        });
      });
    }
    searchForMobile() {
   
      this.products = [];
      this.model = new SearchModel();
      this.model.categoryId = this.CategoryIdForSearch;
      this.model.name = this.searchName;
      this.productService.GetProductsForSearch(this.model, 1)
      .subscribe(data => {
        this.products = data;
        let length = this.products.length / 8;
        if (length % 8) {
          length ++;
        }
        for (let index = 1; index <=  length ; index ++) {
          this.pages.push(index);
         }
        this.products.forEach(element => {
          element.amount = 1;
        });
      });
    }
}