import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { GivingGiftService } from '../../giving-gift.service';
import { GivingGiftModel } from '../../models/giving-gift.model';
class Product{    
    name: string;    
    price: number;    
    pricebefortax: number;    
    qty: number; 
  }    
  class Invoice{    
    customerName: string;    
    address: string;    
    contactNo: string;    
    email: string;    
    products: Product[] = [];    
    additionalDetails: string;    
    orderNumber  : string;  
    tax = 0;
    deleveryPrice = 0;
    copenCode : number = 0;
    balanceUsed : number = 0;
    date:Date;
    total = 0;
    constructor(){    
      // Initially one empty product row we will show     
      this.products.push(new Product());    
    }    
  }  
@Component({
    selector: 'gift-invoice',
    templateUrl: 'gift-invoice.component.html',
    styleUrls: ['gift-invoice.component.scss']
})
export class GiftInvoiceComponent implements OnInit,AfterViewInit {
    @ViewChild('contentToConvert', {static: false}) screen: ElementRef;
    @ViewChild('canvas', {static: false}) canvas: ElementRef;
    @ViewChild('downloadLink', {static: false}) downloadLink: ElementRef;
    downloadImage(){
      html2canvas(this.screen.nativeElement).then(canvas => {
        this.canvas.nativeElement.src = canvas.toDataURL();
        this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
        this.downloadLink.nativeElement.download = 'marble-diagram.png';
        this.downloadLink.nativeElement.click();
      });
    }
    Id = 0;
    qrCodeB64:string = '';
    public productTotal = 0;
      public productTotalBeforeVat = 0;
      public subtotalAfterDiscound = 0;
      public total = 0;
      public couponCode = 0;
      public tax = 0;
      // public flatRate = 100;
      public deleveryPrice = 0;
      public deleveryPriceVat = 0;
      checkoutid :string='';
   constructor(
    private router:Router,
    private route: ActivatedRoute,
    private GivingGiftService:GivingGiftService,
    private datePipe: DatePipe
   ){
    this.Id = + route.snapshot.paramMap.get('Id');
    
   }
      ngAfterViewInit(): void {
        throw new Error('Method not implemented.');
      }
      model:GivingGiftModel={};
      ngOnInit(): void {
        //debugger;
        this.GivingGiftService.GetByIdForInvoice(this.Id)
        .subscribe(data => {
            this.model = data;
            this.GivingGiftService.UpdateAfterPay(this.model)
            .subscribe(data => {
              if(data == true){
                this.total = this.model.amount;
                this.invoice.additionalDetails = '';
                this.invoice.address = '';
                this.invoice.balanceUsed = 0;
                this.invoice.contactNo = this.model.friendPhone;
                this.invoice.copenCode = 0;
                this.invoice.customerName = this.model.friendName;
                this.invoice.date = this.model.createDate;
                this.invoice.email = this.model.friendEmail;
                this.invoice.orderNumber = this.model.designId.toString();
                this.invoice.products = [];
                this.invoice.tax = 0;
                this.invoice.total = this.model.amount;
                this.productTotalBeforeVat = (+this.invoice.products.reduce((sum, p)=> sum + (p.qty * p.price), 0).toFixed(2))/1.15;
                this.couponCode = this.invoice.copenCode;
                this.subtotalAfterDiscound = this.productTotalBeforeVat- this.invoice.copenCode;
                this.productTotal = (+this.invoice.products.reduce((sum, p)=> sum + (p.qty * p.price), 0).toFixed(2));
                this.productTotal -=  this.invoice.copenCode;
                this.deleveryPriceVat = (this.invoice.deleveryPrice/100)* 15  ;
                this.total = (+this.invoice.products.reduce((sum, p)=> sum + (p.qty * p.price), 0).toFixed(2)) + this.invoice.deleveryPrice  + this.deleveryPriceVat - this.invoice.copenCode - this.invoice.balanceUsed;
                this.invoice.tax = 0;
                // this.invoice.products.forEach(element => {
                //   // var t =  (element.price/100)* 15
                //   element.pricebefortax = element.price/1.15;
                //   this.invoice.tax += element.price -element.pricebefortax
                // });
                // var vat = this.subtotalAfterDiscound / 1.15;
                // this.invoice.tax = this.subtotalAfterDiscound - vat;
                this.productTotal = this.subtotalAfterDiscound * 1.15;
                this.total = this.productTotal + this.invoice.deleveryPrice  + this.deleveryPriceVat - this.invoice.balanceUsed;
                var vat= this.productTotal / 1.15;
                this.invoice.tax = this.productTotal - vat;
                // localStorage.removeItem('cartProducts');
                // localStorage.removeItem('cartboxes');
                this.total =this.model.amount;
                this.invoice.date = this.model.createDate;
                var pro = new Product();
                pro.name = this.model.cardName;
                pro.price = this.model.amount;
                pro.qty = 1;
                pro.pricebefortax = this.model.amount;
                this.invoice.products.push(pro);
                var sellerNameBuf=this.getTLVForValue("1","International Chocolate Trading Company");
                // var sellerNameBuf=this.getTLVForValue("1","شركة الشوكولاتة العالمية التجارية");
                var vatRegistrationNameBuf = this.getTLVForValue("2","310387548200003");
                var timeBuf = this.getTLVForValue("3",this.datePipe.transform(this.model.createDate, 'yyyy-MM-dd, h:mm a'));
                var totalBuf = this.getTLVForValue("4",this.total.toFixed(2));
                var totalvat = this.invoice.tax + this.deleveryPriceVat;
                var totalVATBuf = this.getTLVForValue("5",totalvat.toFixed(2));
                var tagBufsArray=[sellerNameBuf,vatRegistrationNameBuf,timeBuf,totalBuf,totalVATBuf];
                 var Buffer = require('buffer/').Buffer
                var qrCodeBuf = Buffer.concat(tagBufsArray);
                this.qrCodeB64 = qrCodeBuf.toString('base64');
                this.openInvoice = true;
              }
            })
        })
     
        
      }
      openInvoice=false;
    invoice = new Invoice(); 
    
    b64EncodeUnicode(str) {
      return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
          return String.fromCharCode();
      }));
  }
    addProduct(){
       
      this.invoice.products.push(new Product());
    } 
    getTLVForValue(tagNum,tagValue){
      //debugger;
      var Buffer = require('buffer/').Buffer
      var tagBuf=Buffer.from([tagNum],'utf8');
      var tagValueLenBuf=Buffer.from([tagValue.length],'utf8');
      var tagValueBuf=Buffer.from(tagValue,'utf8');
      var bufArray = [tagBuf,tagValueLenBuf,tagValueBuf]
      return Buffer.concat(bufArray);
    }
    generatePDF1(action = 'open') {
      var data = this.screen.nativeElement;
      // html2canvas.useCORS = true;
      html2canvas(data, {useCORS : true, logging: true, allowTaint: true }).then(canvas => {
        var imgWidth = 208;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        const contentDataURL = canvas.toDataURL('image/png')
        let pdf = new jspdf('p', 'mm', 'a4');
        var position = 0;
        pdf.addImage(contentDataURL, 'PNG', 0 , position, imgWidth, imgHeight)
        // pdf.addFont('/assets/Janna-LT-Bold.ttf', 'Janna-LT-Bold', 'normal');
        // pdf.setFont('Janna-LT-Bold');
        if(action==='download'){
          pdf.save('newPDF.pdf');
  
        }else if(action === 'print'){
          pdf.output('datauristring');      
        }else{
          pdf.output('dataurlnewwindow');
        }
      });
    }
    public convetToPDF()
    {
      //debugger;
    var data = document.getElementById('contentToConvert');
      // html2canvas.useCORS = true;
    html2canvas(data,{}).then(canvas => {
    // Few necessary setting options
    var imgWidth = 208;
    var pageHeight = 295;
    var imgHeight = canvas.height * imgWidth / canvas.width;
    var heightLeft = imgHeight;
    const contentDataURL = canvas.toDataURL('image/png')
    let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
    var position = 0;
    pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
    pdf.save('new-file.pdf'); // Generated PDF
    
  });
  
    }
  }
  