import { ToastrService } from 'ngx-toastr';
import { ResetPasswordModel } from './../model/reset-password.model';
import { SessionService } from 'src/app/pages/session/session.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { matchingPasswords } from 'src/app/core/utils/app-validators';

@Component({
    selector: 'reset-password',
    templateUrl: 'reset-password.component.html',
    styleUrls: ['reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    public resetPasswordForm: FormGroup;
    public showSuccess: boolean;
    public showError: boolean;
    public errorMessage: string;
    private _token: string;
    private _email: string;
    constructor(private _authService: SessionService,  
      private _route: ActivatedRoute,
      public toaster : ToastrService,
      private router: Router
      ) { }
    ngOnInit(): void {
      this.resetPasswordForm = new FormGroup({
        password: new FormControl('', [Validators.required]),
        confirm: new FormControl('')
      });
        this._token = this._route.snapshot.queryParams['token'];
        this._email = this._route.snapshot.queryParams['email'];
    }
 
    public resetPassword = (resetPasswordFormValue) => {
        this.showError = this.showSuccess = false;
        const resetPass = { ... resetPasswordFormValue };
        const resetPassDto: ResetPasswordModel = {
          password: resetPass.password,
          confirmPassword: resetPass.confirm,
          code: this._token,
          email: this._email
        }
        this._authService.resetPassword(resetPassDto)
        .subscribe(_ => {
          this.toaster.success('your password has been reset');
          this.router.navigate(['login']);
        },
        error => {
          this.showError = true;
          this.errorMessage = error;
        })
      }
      public validateControl = (controlName: string) => {
        return this.resetPasswordForm.controls[controlName].invalid && this.resetPasswordForm.controls[controlName].touched
      }
      public hasError = (controlName: string, errorName: string) => {
        return this.resetPasswordForm.controls[controlName].hasError(errorName)
      }
}
