import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { FooterModule } from 'src/app/layouts/footer/footer.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxImageZoomModule  } from 'ngx-image-zoom';
import { LightboxModule } from 'ngx-lightbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'src/app/components/components.module';
import { HeaderModule } from 'src/app/layouts/header/header.module';
import { MatRadioModule, MatTabsModule } from '@angular/material';
import { GivingGiftTwoComponent } from './giving-gift-two.component';
import { QRCodeModule } from 'angular2-qrcode';




@NgModule({
  declarations: [GivingGiftTwoComponent],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgxImageZoomModule.forRoot(),
    LightboxModule,
    AppRoutingModule,
    TranslateModule,
    ComponentsModule,
    FormsModule,
    // Specify your library as an import
    SlickCarouselModule,
    MatTabsModule,
    HeaderModule,
    FooterModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    MatRadioModule,
    QRCodeModule,

  ],
  exports:[GivingGiftTwoComponent],
  schemas: [ NO_ERRORS_SCHEMA ],
  bootstrap: [GivingGiftTwoComponent]
})
export class GivingGiftTwoModule { }
