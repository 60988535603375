import { Component } from '@angular/core';

@Component({
    selector: 'error-on-pay',
    templateUrl: 'error-on-pay.component.html',
    styleUrls: ['error-on-pay.component.scss']
})
export class ErrorOnPayComponent {

}
