import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserModel } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem('token');
   httpOptions = {
    headers: new HttpHeaders({
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }

  getAll(page: number): Observable<UserModel[]> {
    return this.http
      .get<UserModel[]>(`${this.baseUrl}/User/GetAll/${page}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCount(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/User/GetCount`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetUserAccountAmount(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/User/GetUserAccountAmount`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  UseUserAccountAmount(amount : number): Observable<boolean> {
    return this.http
      .put<boolean>(`${this.baseUrl}/User/UseUserAccountAmount` , amount , this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetById(id: number): Observable<UserModel> {
      return this.http
      .get<UserModel>(`${this.baseUrl}/User/GetById/${id}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
     }
     ResentCodeAsync(id: number): Observable<UserModel> {
      return this.http
      .get<UserModel>(`${this.baseUrl}/User/ResentCodeAsync/${id}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
     }
     
  GetCurrentUser(): Observable<UserModel> {
        return this.http
        .get<UserModel>(`${this.baseUrl}/User/GetCurrentUser`, this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error.json())));
       }
       createUser(payload: UserModel): Observable<boolean> {
        return this.http
          .post<boolean>(`${this.baseUrl}/account/SaveUser`, payload, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
      put(payload: UserModel): Observable<boolean> {
        return this.http
          .put<boolean>(`${this.baseUrl}/User/Update`, payload, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
      delete(payload: UserModel): Observable<boolean> {
        return this.http
          .delete<boolean>(`${this.baseUrl}/User/Delete/${payload.id}`, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
 
}
