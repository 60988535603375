import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ChangePasswordModel } from '../model/change-password.model';
import { SessionService } from '../session.service';

@Component({
    // moduleId: module.id,
    selector: 'change-password',
    templateUrl: 'change-password.component.html',
    styleUrls: ['change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
    form : FormGroup;
    changePasswordModel: ChangePasswordModel = {};
    openForm = false;
    constructor(
      private fb: FormBuilder,
      private sessionService:SessionService,
      public toaster:ToastrService,
      private router: Router
    ) { }
  
    ngOnInit() {
   
      this.changePasswordModel = new ChangePasswordModel();
      this.form = this.fb.group({
        oldPassword: [this.changePasswordModel.oldPassword, Validators.required],
        newPassword: [this.changePasswordModel.newPassword, Validators.required],
        confirmPassword: [this.changePasswordModel.confirmPassword, Validators.required],
        code: [this.changePasswordModel.code],
      })
      this.openForm = true;
    }
    submit(){
       
      this.changePasswordModel = this.form.getRawValue();
      this.changePasswordModel.code = localStorage.getItem('token');
      this.sessionService.changePassword(this.changePasswordModel)
      .subscribe(result => {
        if(result === 'Password incorrect'){
          this.toaster.error('Password incorrect');
        }
        else{
          this.toaster.success('Password Changed');
          this.router.navigate(['login']);
        }
      },error=>{
        this.toaster.error('Password incorrect');
      }

      );
    }
  
  }
  