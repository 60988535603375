import { ProductUnitModel } from "../../shop/models/product-unit.model";

export class CartModel{
    id?: number;
    userId?: number;
    productNameEn?: string;
    productNameAr?: string;
    imageUrl?: string;
    productId?: number;
    quantity?: number;
    count?: number;
    productPrice?: number;
    totalPrice?: number;
    unitPrice?: number;
    producUnitId?: number;
    amount ?: number;
    avalable?: boolean;
    productUnits?: ProductUnitModel[] = [];
    constructor(cart ?: CartModel){
        cart = cart ? cart : {};
        this.id = cart.id ? cart.id : 0;
        this.userId = cart.userId ? cart.userId : 0;
        this.amount = cart.amount ? cart.amount : 0;
        this.producUnitId = cart.producUnitId ? cart.producUnitId : 0;
        this.unitPrice = cart.unitPrice ? cart.unitPrice : 0;
        this.totalPrice = cart.totalPrice ? cart.totalPrice : 0;
        this.productId = cart.productId ? cart.productId : 0;
        this.productNameEn = cart.productNameEn ? cart.productNameEn : '';
        this.productNameAr = cart.productNameAr ? cart.productNameAr : '';
        this.imageUrl = cart.imageUrl ? cart.imageUrl : '';
        this.quantity = cart.quantity ? cart.quantity : 1;
        this.count = cart.count ? cart.count : 0;
        this.productPrice = cart.productPrice ? cart.productPrice : 0;
        this.productUnits = cart.productUnits ? cart.productUnits : [];
        this.avalable = cart.avalable ? cart.avalable : true;
    }
}