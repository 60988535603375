import { BoxProductsModel } from "../../boxes/models/box-products.model";

export class BoxCartModel{
    id?: number;
    userId?: number;
    boxNameEn?: string;
    boxNameAr?: string;
    imageUrl?: string;
    boxId?: number;
    quantity?: number;
    count?: number;
    boxPrice?: number;
    totalPrice?: number;
    amount ?: number;
    orderId ?: number;
    avalable?: boolean;
    isActive?: boolean;
    products?:BoxProductsModel[];
    constructor(cart ?: BoxCartModel){
        cart = cart ? cart : {};
        this.id = cart.id ? cart.id : 0;
        this.userId = cart.userId ? cart.userId : 0;
        this.amount = cart.amount ? cart.amount : 0;
        this.totalPrice = cart.totalPrice ? cart.totalPrice : 0;
        this.boxId = cart.boxId ? cart.boxId : 0;
        this.boxNameEn = cart.boxNameEn ? cart.boxNameEn : '';
        this.boxNameAr = cart.boxNameAr ? cart.boxNameAr : '';
        this.imageUrl = cart.imageUrl ? cart.imageUrl : '';
        this.quantity = cart.quantity ? cart.quantity : 1;
        this.count = cart.count ? cart.count : 0;
        this.boxPrice = cart.boxPrice ? cart.boxPrice : 0;
        this.orderId = cart.orderId ? cart.orderId : 0;
        this.avalable = cart.avalable ? cart.avalable : true;
        this.isActive = cart.isActive ? cart.isActive : true;
        this.products = cart.products ? cart.products : [];
    }
}