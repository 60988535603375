import { Component, OnDestroy, OnInit, Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { timer } from 'rxjs/internal/observable/timer';
import { Subscription } from 'rxjs/internal/Subscription';
import { UserRegistration } from 'src/app/pages/session/model/user-registration';
import { SessionService } from 'src/app/pages/session/session.service';
import { UserModel } from 'src/app/pages/user-account/models/user.model';
import { UserService } from 'src/app/pages/user-account/services/user.service';

@Component({
    selector: 'confirm-code-modal',
    templateUrl: 'confirm-code-modal.component.html',
    styleUrls: ['confirm-code-modal.component.scss']
})
export class ConfirmCodeModalComponent implements OnInit, OnDestroy {
    confirmForm: FormGroup;
    openForm = false;
    userId = 0;
    user: UserModel = {};
    UserRegistration: UserRegistration = {};
    constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private userService: UserService,
      private sessionService: SessionService,
      private router: Router ,
      public toaster: ToastrService,
      public dialogRef : MatDialogRef<ConfirmCodeModalComponent>
      ){
        //   this.userId = + this.route.snapshot.paramMap.get('Id');
      }
      time = 5000;
      resend=false;
      startDate=new Date();
      endDate=new Date().setMinutes(5);
      triggerFunction(){
          return new Date();
      }
      countDown:Subscription;
      counter = 100;
      tick = 1000;
    
      ngOnInit(): void {
          this.countDown = timer(0, this.tick)
          .subscribe(() => --this.counter)
          this.userService.GetById(this.userId)
          .subscribe(data => {
              this.user = data;
              if(this.user.isSaudi == true){
                this.openForm = true;
                this.confirmForm = this.formBuilder.group({
                    code : ['' , Validators.required]
                  });
                this.sessionService.sendSMS(this.user.code,this.user.phone)
                .subscribe(result => {
             
                })
           
              }
              else{
                this.openForm = true;
                this.confirmForm = this.formBuilder.group({
                    code : ['' , Validators.required]
                  });
              }
       
             
          });
     
  
      }
      
      resentCode(){
          this.resend = true;
      }
      ResendCode(){
         
          this.counter = 100;
          this.userService.ResentCodeAsync(this.userId)
          .subscribe(data => {
            this.user = new UserModel();
              this.user = data;
              if(this.user.isSaudi == true){
                this.openForm = true;
                this.countDown = timer(0, this.tick)
                .subscribe(() => --this.counter)
                this.confirmForm = this.formBuilder.group({
                    code : ['' , Validators.required]
                  });
                this.sessionService.sendSMS(this.user.code,this.user.phone)
                .subscribe(result => {
               
                })
           
              }
              else{
                this.openForm = true;
                this.countDown = timer(0, this.tick)
                .subscribe(() => --this.counter)
                this.confirmForm = this.formBuilder.group({
                    code : ['' , Validators.required]
                  });
              }
          });
      }
      submit() {
        
          const formData = this.confirmForm.getRawValue();
          if(this.user.code == formData.code){
              this.UserRegistration.address = this.user.address;
              this.UserRegistration.confirmPassword = this.user.password;
              this.UserRegistration.email = this.user.email;
              this.UserRegistration.gender = this.user.gender;
              this.UserRegistration.isActive = true;
              this.UserRegistration.name = this.user.email;
              this.UserRegistration.password = this.user.password;
              this.UserRegistration.phone = this.user.phone;
              this.UserRegistration.userName = this.user.email;
              this.UserRegistration.cityId = this.user.cityId;
              this.UserRegistration.reagionId = this.user.reagionId;
              this.sessionService.ConfirmRegister(this.UserRegistration)
              .subscribe(data => {
                if (data != null) {
                  localStorage.setItem('token', data.token);
                  localStorage.setItem('currentUser', JSON.stringify(data));
                  this.router.navigate(['/checkout']);
                  this.sessionService.loggedIn = true;
                  this.dialogRef.close()
                 }
                 else{
                  this.toaster.error('please enter the true code');
                 }
              });
          }
          else{
              this.toaster.error('please enter the true code');
             }
      }
      ngOnDestroy(){
          this.countDown=null;
        }
  
  }
  @Pipe({
      name: "formatTime"
    })
    export class FormatTimePipe implements PipeTransform {
      transform(value: number): string {
        const minutes: number = Math.floor(value / 60);
        return (
          ("00" + minutes).slice(-2) +
          ":" +
          ("00" + Math.floor(value - minutes * 60)).slice(-2)
        );
      }
    }
  