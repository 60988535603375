export class MessageModel{
    id?: number;
    name?: string;
    mobile?: string;
    email?: string;
    subject?: string;
    theMessage?: string;
    isView?: boolean;
    constructor(message?: MessageModel){
        message = message ? message : {};
        this.id = message.id ? message.id : 0;
        this.name = message.name ? message.name : '';
        this.mobile = message.mobile ? message.mobile : '';
        this.email = message.email ? message.email : '';
        this.isView = message.isView ? message.isView : false;
        this.subject = message.subject ? message.subject : '';
        this.theMessage = message.theMessage ? message.theMessage : '';
    }
}