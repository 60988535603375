import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { observable, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CorporateGiftModel } from './models/corporategift.model';

@Injectable({
  providedIn: 'root'
})
export class CorporateGiftService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem('token');
   httpOptions = {
    headers: new HttpHeaders({
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {
  }
  GetAll(page: number): Observable<CorporateGiftModel> {
    return this.http
      .get<CorporateGiftModel>(`${this.baseUrl}/CorporateGift/GetAll/${page}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetById(id: number): Observable<CorporateGiftModel> {
    return this.http
      .get<CorporateGiftModel>(`${this.baseUrl}/CorporateGift/GetById/${id}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  } 
  GetCount(): Observable<CorporateGiftModel> {
    return this.http
      .get<CorporateGiftModel>(`${this.baseUrl}/CorporateGift/GetCount`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  CreateCorporateGift(payload: CorporateGiftModel): Observable<boolean>{
      return this.http
      .post<boolean>(`${this.baseUrl}/CorporateGift/Insert`, payload ,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  View(payload: CorporateGiftModel): Observable<boolean> {
        return this.http
          .put<boolean>(`${this.baseUrl}/CorporateGift/View`, payload, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
  Delete(id: number): Observable<boolean> {
        return this.http
          .delete<boolean>(`${this.baseUrl}/CorporateGift/Delete/${id}`, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
}
