export class ShoppingAddressModel {
    id?: number;
    userName   ?: string;
    userId     ?: number;
    firstName  ?: string;
    lastName   ?: string;
    companyName?: string;
    address    ?: string;
    city       ?: string;
    postalCode ?: string;
    phone      ?: string;
    resionName ?: string;
    resionId   ?: number;
    cityId     ?: number;
    isMain     ?: boolean;
    constructor(address ?: ShoppingAddressModel) {
        address = address ? address : {};
        this.id = address.id ? address.id : 0 ;
        this.userName    = address.userName    ? address.userName    : '' ;
        this.userId      = address.userId      ? address.userId      : 0 ;
        this.firstName   = address.firstName   ? address.firstName   : '' ;
        this.lastName    = address.lastName    ? address.lastName    : '' ;
        this.companyName = address.companyName ? address.companyName : '' ;
        this.address     = address.address     ? address.address     : '' ;
        this.city        = address.city        ? address.city        : '' ;
        this.postalCode  = address.postalCode  ? address.postalCode  : '' ;
        this.phone       = address.phone       ? address.phone       : '' ;
        this.resionName  = address.resionName  ? address.resionName  : '' ;
        this.resionId    = address.resionId    ? address.resionId    : 0 ;
        this.cityId      = address.cityId      ? address.cityId      : 0 ;
        this.isMain      = address.isMain      ? address.isMain      : false ;
    }
}