import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApplyJobModel } from './models/apply-job.model';
@Injectable({
  providedIn: 'root'
})
export class ApplyJobService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem('token');
   httpOptions = {
    headers: new HttpHeaders({
       'Authorization': 'Bearer' + this.token,
       'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) {

  }
  createApplyJob(payload: ApplyJobModel): Observable<boolean> {
    return this.http
      .post<boolean>(`${this.baseUrl}/ApplyJob/Insert`, payload, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  put(payload: ApplyJobModel): Observable<boolean> {
    return this.http
      .put<boolean>(`${this.baseUrl}/ApplyJob/Update`, payload, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
}
