import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { JobModel } from './models/job.model';

@Injectable({
  providedIn: 'root'
})
export class JobService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem('token');
   httpOptions = {
    headers: new HttpHeaders({
       'Authorization': 'Bearer' + this.token,
       'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) {

  }
  getAll(page: number): Observable<JobModel[]> {
    return this.http
      .get<JobModel[]>(`${this.baseUrl}/Job/GetAll/${page}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCount(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/Job/GetCount`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetById(id: number): Observable<JobModel> {
      return this.http
      .get<JobModel>(`${this.baseUrl}/Job/GetById/${id}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
     }
}
