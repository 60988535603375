// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
    // DevApiUrl: 'http://localhost:60289/api',
    // DevImageUrl: 'http://localhost:60289',
    // DevPayUrl:"http://localhost:60289",
    //  DevImageUrl : 'https://localhost:5001'
    DevApiUrl: 'https://apis.laderach.sa/api',
    DevImageUrl: 'https://apis.laderach.sa',
    DevPayUrl:"https://apis.laderach.sa",
    SMSAPIKey:"6sAcRJxZim9A7PS9F2coSvkQN8SWEonZP21abiFwn3g=",
    SMSClientId:"5021ce75-778c-42d6-a91d-0706d1210c4c",
    SMSSenderId:"Laderach",
    GiftCallBackUrl:'https://laderach.sa/gift-invoice/',
    CallBackUrl:'https://laderach.sa/invoice/',
    ErrorUrl : 'https://laderach.sa/error-on-pay'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
