import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailValidator } from 'src/app/core/utils/app-validators';
import { MessageModel } from 'src/app/models/message.model';
import { MessageService } from 'src/app/pages/user-account/services/message.service';

@Component({
  selector: 'app-newsletter-modal',
  templateUrl: './newsletter-modal.component.html',
  styleUrls: ['./newsletter-modal.component.scss']
})
export class NewsletterModalComponent implements OnInit {
  message: MessageModel = {};
  Form: FormGroup;
  result = 'no';
  constructor(private messageService: MessageService, private fb: FormBuilder) {
   }
   createForm(): FormGroup {
    return this.fb.group({
      id: [this.message.id],
      email: [this.message.email, Validators.compose([Validators.required, emailValidator])],
      isView: [this.message.isView],
      mobile: [this.message.mobile],
      name: [this.message.name],
      subject: [this.message.subject],
      theMessage: [this.message.theMessage],
    });
  }
  ngOnInit() {
    this.message = new MessageModel();
    this.Form = this.createForm();
  }
  onSubmit() {
    this.message = this.Form.getRawValue();
    this.messageService.CreateMessage(this.message)
    .subscribe(data => {
      if ( data === true ) {
        localStorage.setItem('message' , 'yes');
      }
    });
  }

}
