import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from 'src/app/app-routing.module';
import { TranslateModule } from '@ngx-translate/core';


import { ComponentsModule } from '../../components/components.module';
import { HeaderModule } from '../../layouts/header/header.module';
import { FooterModule } from 'src/app/layouts/footer/footer.module';


import { LogInOneComponent } from './log-in-one/log-in-one.component';
import { LogInTwoComponent } from './log-in-two/log-in-two.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmCodeComponent, FormatTimePipe } from './confirm-code/confirm-code.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CountdownTimerModule } from 'angular-countdown-timer';


@NgModule({
  declarations: [
    ConfirmCodeComponent ,
    LogInOneComponent,
    LogInTwoComponent,
    SignUpComponent,
    ThankYouComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    FormatTimePipe
  ],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule,
    // NgSelectModule,
    ComponentsModule,
    HeaderModule,
    FooterModule,
    FormsModule,
    ReactiveFormsModule,
    CountdownTimerModule.forRoot()
  ],
  exports: [LogInOneComponent,
     LogInTwoComponent,
      SignUpComponent,
       ThankYouComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
       ],
   schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SessionModule { }
