import { GivingGiftModel } from './../../models/giving-gift.model';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { KatoraService } from 'src/app/services/katora.service';
import { GivingGiftService } from '../../giving-gift.service';
import { GiftMessageDesignMode } from '../../models/gift-message-design.model';

@Component({
    selector: 'gift-for-user',
    templateUrl: 'gift-for-user.component.html',
    styleUrls: ['gift-for-user.component.scss']
})
export class GiftForUserComponent implements OnInit {
    GiftId = 0;
    givings: GiftMessageDesignMode[] = [];
    openForm = false;
    count = 0;
    currenPage = 1;
    pages: number [] = [] ;
    constructor(
        private route: ActivatedRoute,
        private sanitization: DomSanitizer,
         private katoraService:KatoraService,
         private http: HttpClient,
        private givingGiftService : GivingGiftService,
        public toastr : ToastrService,
        private router:Router
      ) { 
      }
    ngOnInit(): void {
      
        this.givingGiftService.GetForCurrentUser()
        .subscribe(data => {
            this.givings = data;
            this.count = this.givings.length;
            this.openForm = true;
            let length = this.givings.length / 8;
            if(length % 8){
              length ++;
            }
            for (let index = 1; index <=  length ; index ++) {
              this.pages.push(index);
             }
        });
    }
    saveImage(item){
        return this.sanitization.bypassSecurityTrustStyle(`url(${item})`);
      }
      onApply(gift : GiftMessageDesignMode){
        const token = localStorage.getItem('token');
        if (token != null) {
          this.givingGiftService.Apply(gift.id)
          .subscribe(data => {
              if(data == "Apply Done Success"){
                  this.toastr.success(data);
                  this.ngOnInit();
              }
              else{
                  this.toastr.warning(data);
                  this.router.navigate(['login'])
              }
          });
        }
        else{
            this.toastr.error("Please Login To Apply Your Gift");
        }
      }
      incrementPage() {
        if (this.currenPage < this.pages.length) {
          this.currenPage ++ ;
        }
      }
      decrementPage() {
      if (this.currenPage > 1) {
        this.currenPage -- ;
      }
      }
      changePage(page: number) {
        this.currenPage = page;
      }
}
