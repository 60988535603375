export class CorporateGiftProductsModel{
    id?: number;
    corporateGiftId?: number;
    productId?: number;
    productNameEn?: string;
    productNameAr?: string;
    productImage?: string;
    productPrice?: number;
    quantity?:number;
    constructor(item ?: CorporateGiftProductsModel){
        item = item ? item : {};
        this.id = item.id ? item.id : 0;
        this.corporateGiftId = item.corporateGiftId ? item.corporateGiftId : 0;
        this.productId = item.productId ? item.productId : 0;
        this.productNameEn = item.productNameEn ? item.productNameEn : '';
        this.productNameAr = item.productNameAr ? item.productNameAr : '';
        this.productImage = item.productImage ? item.productImage : '';
        this.productPrice = item.productPrice ? item.productPrice : 0;
        this.quantity = item.quantity ? item.quantity : 1;
    }
}