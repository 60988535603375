import { Component, OnInit } from '@angular/core';
import { CategoryModel } from 'src/app/pages/shop/models/category.model';
import { CategoryService } from 'src/app/services/category.service';
import { KatoraService } from 'src/app/services/katora.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  categories: CategoryModel[] = [];
  public visDiv=true;
  public current_language = 'ar';
  constructor(private katoraService: KatoraService , private categoryService: CategoryService ){ 
    this.current_language = localStorage.getItem('lang');
  }
  onAddCart(product: any) {
    this.visDiv = false;
    this.katoraService.addProductToCart(product);
    setTimeout(() => {
      this.visDiv = true;
  
    }, 3000);

  }

  onAddWishlist(product: any){
    
    this.katoraService.addProductToWishlist(product);

  }

  ngOnInit() {
    this.categoryService.GetAllForDropdownMenu()
    .subscribe(data => {
      this.categories = data;
      this.categories.forEach(element => {
        if(element.hideFromDropdownMenu == true){
          var i = this.categories.findIndex(x=>x.id == element.id);
          if(i > -1){
            this.categories.splice(i,1);
          }
        }
      });
    });
  }
}
