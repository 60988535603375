export class UserRegistration {
    userName?:        string;
    name?:            string;
    phone?:           string;
    email?:           string;
    password?:        string;
    confirmPassword?: string;
    isActive?:        boolean;
    routeUrl?:        string;
    reagionId?: number;
    gender?: string;
    address?: string;
    cityId?: number;
    isSaudi ?: boolean;
    constructor(user?: UserRegistration){
       user=user?user:{};
       this.userName=this.userName?this.userName:'';
      this.name=this.name?this.name:'';
      this.phone=this.phone?this.phone:'';
      this.email=this.email?this.email:'';
      this.password=this.password?this.password:'';
      this.confirmPassword=this.confirmPassword?this.confirmPassword:'';
      this.isActive=this.isActive?this.isActive:true;
      this.routeUrl=this.routeUrl?this.routeUrl:'';
      this.reagionId=this.reagionId?this.reagionId:0;
      this.cityId=this.cityId?this.cityId:0;
      this.gender=this.gender?this.gender:'';
      this.address=this.address?this.address:'';
      this.isSaudi=this.isSaudi?this.isSaudi:true;


    }
}