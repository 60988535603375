export class PurchaseCouponModel {
    id?: number;
    code?: string;
    amount?: number;
    isUsed?: boolean;
    usedBy?: string;
    usedDate?: Date;
    paymentType?: number;
    constructor(purchase?: PurchaseCouponModel){
        purchase = purchase ? purchase : {};
        this.id = purchase.id ? purchase.id : 0;
        this.isUsed = purchase.isUsed ? purchase.isUsed : false;
        this.paymentType = purchase.paymentType ? purchase.paymentType : 0 ;
        this.usedBy = purchase.usedBy ? purchase.usedBy : '';
        this.usedDate = purchase.usedDate ? purchase.usedDate : null;
        this.amount = purchase.amount ? purchase.amount : 0;
        this.code = purchase.code ? purchase.code : '';
    }
}