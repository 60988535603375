import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-sticky',
  templateUrl: './header-sticky.component.html',
  styleUrls: ['./header-sticky.component.scss']
})
export class HeaderStickyComponent implements OnInit {
  searchName :string;
  Form : FormGroup ;
  constructor(
    private router: Router,
    private fb : FormBuilder
  ) { }

  ngOnInit() {
    this.Form = this.fb.group({
      searchName : [this.searchName]
    })
  }

  search() {
    const item = this.Form.getRawValue();
    if (item.searchName === '') {
      item.searchName = ' ';
    }
      this.router.navigate([`shop-search/0/1/${item.searchName}`]);
  }
}
