import { CreateMyBoxComponent } from './pages/boxes/components/create-my-box/create-my-box.component';
import { ViewGiftCardComponent } from './pages/gift-cards/components/view-gift-card/view-gift-card.component';
import { GivingGiftComponent } from './pages/gift-cards/components/giving-gift/giving-gift.component';
import { GiftCardsComponent } from './pages/gift-cards/components/gift-cards/gift-cards.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './pages/home/home-one/home-one.component';
import { HomeTwoComponent } from './pages/home/home-two/home-two.component';
import { HomeThreeComponent } from './pages/home/home-three/home-three.component';
import { ShopOneComponent } from './pages/shop/shop-one/shop-one.component';
import { ShopTwoComponent } from './pages/shop/shop-two/shop-two.component';
import { ShopThreeComponent } from './pages/shop/shop-three/shop-three.component';
import { ShopFourComponent } from './pages/shop/shop-four/shop-four.component';
import { ShopFiveComponent } from './pages/shop/shop-five/shop-five.component';
import { ProductOneComponent } from './pages/product-single/product-one/product-one.component';
import { ProductTwoComponent } from './pages/product-single/product-two/product-two.component';
import { ProductThreeComponent } from './pages/product-single/product-three/product-three.component';
import { ProductFourComponent } from './pages/product-single/product-four/product-four.component';
import { ProductFiveComponent } from './pages/product-single/product-five/product-five.component';
import { LogInTwoComponent } from './pages/session/log-in-two/log-in-two.component';
import { LogInOneComponent } from './pages/session/log-in-one/log-in-one.component';
import { SignUpComponent } from './pages/session/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './pages/session/forgot-password/forgot-password.component';
import { CartOneComponent } from './pages/cart/cart-one/cart-one.component';
import { CartTwoComponent } from './pages/cart/cart-two/cart-two.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { ProfileComponent } from './pages/user-account/profile/profile.component';
import { WishlistComponent } from './pages/user-account/wishlist/wishlist.component';
import { CompareComponent } from './pages/user-account/compare/compare.component';
import { OrdersComponent } from './pages/user-account/orders/orders.component';
import { OrderDetailComponent } from './pages/user-account/order-detail/order-detail.component';
import { ShippingAddressComponent } from './pages/user-account/shipping-address/shipping-address.component';
import { BlogOneComponent } from './pages/blog/blog-one/blog-one.component';
import { BlogSingleComponent } from './pages/blog/blog-single/blog-single.component';
import { BlogTwoComponent } from './pages/blog/blog-two/blog-two.component';
import { ThankYouComponent } from './pages/session/thank-you/thank-you.component';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { AboutOneComponent } from './pages/about/about-one/about-one.component';
import { AboutTwoComponent } from './pages/about/about-two/about-two.component';
import { ContactOneComponent } from './pages/about/contact-one/contact-one.component';
import { ContactTwoComponent } from './pages/about/contact-two/contact-two.component';
import { PrivacyPolicyComponent } from './pages/about/privacy-policy/privacy-policy.component';
import { TermAndConditionComponent } from './pages/about/term-and-condition/term-and-condition.component';
import { RefundPolicyComponent } from './pages/about/refund-policy/refund-policy.component';
import { StoreLocationsComponent } from './pages/store-locations/store-locations.component';
import { ShopSearchComponent } from './pages/shop/shop-search/shop-search.component';
import { AllPurchaseCouponsComponent } from './pages/user-account/purchase-coupons/all-purchase-coupons/all-purchase-coupons.component';
import { ConfirmCodeComponent } from './pages/session/confirm-code/confirm-code.component';
import { AllJobsComponent } from './pages/apply-job/components/all-jobs/all-jobs.component';
import { JopDetailsComponent } from './pages/apply-job/components/jop-details/jop-details.component';
import { ChangePasswordComponent } from './pages/session/change-password/change-password.component';
import { GiftForUserComponent } from './pages/gift-cards/components/gift-for-user/gift-for-user.component';
import { ResetPasswordComponent } from './pages/session/reset-password/reset-password.component';
import { InvoiceComponent } from './pages/checkout/invoice/invoice.component';
import { ErrorOnPayComponent } from './pages/checkout/error-on-pay/error-on-pay.component';
import { CheckoutAsAGuestComponent } from './pages/checkout/checkout-as-a-guest/checkout-as-a-guest.component';
import { CorporateGiftComponent } from './pages/corporate-gifts/components/corporate-gift/corporate-gift.component';
import { AllBoxesComponent } from './pages/boxes/components/all-boxes/all-boxes.component';
import { GivingGiftTwoComponent } from './pages/gift-cards/components/giving-gift-two/giving-gift-two.component';
import { GiftInvoiceComponent } from './pages/gift-cards/components/gift-invoice/gift-invoice.component';
import { GivingGiftTwoEnComponent } from './pages/gift-cards/components/giving-gift-two-en/giving-gift-two-en.component';
import { GiftCardsTwoComponent } from './pages/gift-cards/components/gift-cards-two/gift-cards-two.component';
const routes: Routes = [
  { path: '', component: HomeOneComponent },
  { path: 'home-two', component: HomeTwoComponent },
  { path: 'home-three', component: HomeThreeComponent },
  { path: 'shop/:Id/:page', component: ShopOneComponent },
  {path: 'confirm-code/:Id', component: ConfirmCodeComponent},
  { path: 'shop-search/:CategoryId/:page/:name', component: ShopSearchComponent },
  { path: 'shop-two', component: ShopTwoComponent },
  { path: 'shop-three', component: ShopThreeComponent },
  { path: 'shop-four', component:  ShopFourComponent},
  { path: 'shop-five', component:  ShopFiveComponent},
  { path: 'all-jobs', component: AllJobsComponent},
  { path: 'boxes', component: AllBoxesComponent},
  { path: 'my-box/:Id', component: CreateMyBoxComponent},
  
  { path: 'job-details/:Id', component: JopDetailsComponent},
  { path: 'product-one/:Id', component: ProductOneComponent },
  { path: 'product-details/:Id', component: ProductTwoComponent },
  { path: 'product-three', component: ProductThreeComponent },
  { path: 'product-four', component:  ProductFourComponent},
  { path: 'product-five', component:  ProductFiveComponent},
  { path: 'reset-password', component:  ResetPasswordComponent},
  { path: 'error-on-pay', component: ErrorOnPayComponent},
  { path: 'login', component:  LogInOneComponent},
  { path: 'login-two', component:  LogInTwoComponent},
  { path: 'signup', component:  SignUpComponent},
  { path: 'change-password', component:  ChangePasswordComponent},
  { path: 'forgot', component:  ForgotPasswordComponent},
  { path: 'cart-one', component: CartOneComponent },
  { path: 'cart-two', component: CartTwoComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'checkout-as-a-guest', component: CheckoutAsAGuestComponent },
  { path: 'invoice/:Id', component: InvoiceComponent },
  { path: 'gift', component: GiftCardsComponent },
  {path: 'giving-gift/:Id' , component: GivingGiftTwoComponent},
  {path: 'giving-gift-en/:Id' , component: GivingGiftTwoEnComponent},
  
  {path: 'view-gift/:Id' , component: ViewGiftCardComponent},
  {path: 'gift-invoice/:Id' , component: GiftInvoiceComponent},
  { path: 'profile', component:  ProfileComponent},
  { path: 'wishlist', component:  WishlistComponent},
  { path: 'my-gifts', component:  GiftForUserComponent},
  { path: 'compare', component:  CompareComponent},
  
  { path: 'my-orders', component:  OrdersComponent},
  { path: 'order-detail/:Id', component: OrderDetailComponent},
  { path: 'shipping-addresses', component: ShippingAddressComponent},
  { path: 'purchase-coupons', component: AllPurchaseCouponsComponent },
  { path: 'blog-one', component: BlogOneComponent },
  { path: 'blog-two', component: BlogTwoComponent },
  { path: 'blog-single-post', component: BlogSingleComponent },
  { path: 'thank-you', component: ThankYouComponent },
  

  { path: 'about-one', component: AboutOneComponent },
  { path: 'about-two', component: AboutTwoComponent },
  { path: 'contact', component: ContactOneComponent },
  { path: 'corporate-gift', component: CorporateGiftComponent },
  { path: 'contact-two', component: ContactTwoComponent },
  { path: 'privacy', component: PrivacyPolicyComponent },
  { path: 'terms', component: TermAndConditionComponent },
  { path: 'refund', component: RefundPolicyComponent },
  { path: 'locations', component: StoreLocationsComponent },
  { path: '**', component: NotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
