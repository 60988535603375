import { UserService } from './../../pages/user-account/services/user.service';
import { logging } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { WishListService } from 'src/app/pages/user-account/services/wish-list.service';
import { KatoraService } from 'src/app/services/katora.service';

@Component({
  selector: 'app-account-dropdown',
  templateUrl: './account-dropdown.component.html',
  styleUrls: ['./account-dropdown.component.scss']
})
export class AccountDropdownComponent implements OnInit {

  public wishlistCount;
  userAccountAmount = 0 ;
  constructor(
    private katoraService : KatoraService , 
    private wishlistService: WishListService,
    private userService:UserService
    ) { 
    
  }
  ngOnInit() {
  this.userService.GetUserAccountAmount()
  .subscribe(amount => {
    this.userAccountAmount = amount;
    if(amount == null){
      this.userAccountAmount = 0;
    }
  });
  }
  ngAfterContentChecked(){
    this.wishlistCount = this.katoraService.getWishlistCount();
  }
  logout(){
    localStorage.removeItem('token');
  }

}
