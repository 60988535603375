export class ApplyJobModel {
  id?: number;
  jobId   ?: number;
  jobTitle?: string ;
  name    ?: string ;
  phone   ?: string ;
  address ?: string ;
  email   ?: string ;
  fileUrl ?: string ;
  gender ?: string ;
  nationalltiy ?: string ;
  cityId ?: number ;
  city ?: string;
  age ?: number ;
  constructor(job?: ApplyJobModel){
      job = job ? job : {};
      this.id = job.id ? job.id : 0;
      this.jobId    = job.jobId    ? job.jobId    : 0;
      this.jobTitle = job.jobTitle ? job.jobTitle : '';
      this.name     = job.name     ? job.name     : '';
      this.phone    = job.phone    ? job.phone    : '';
      this.address  = job.address  ? job.address  : '';
      this.email    = job.email    ? job.email    : '';
      this.fileUrl  = job.fileUrl  ? job.fileUrl  : '';
      this.gender  = job.gender  ? job.gender  : '';
      this.nationalltiy  = job.nationalltiy  ? job.nationalltiy  : '';
      this.city  = job.city  ? job.city  : '';
      this.cityId  = job.cityId  ? job.cityId  : 0;
      this.age  = job.age  ? job.age  : 0;
  }
}