import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BoxModel } from './models/Box.model';
@Injectable({
  providedIn: 'root'
})
export class BoxService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }
  getAll(page:number): Observable<BoxModel[]> {
    return this.http
      .get<BoxModel[]>(`${this.baseUrl}/Box/GetAll/${page}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
 
  GetCount(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/Box/GetCount`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  createBox(payload: BoxModel): Observable<boolean> {
    console.log(payload);
    return this.http
      .post<boolean>(`${this.baseUrl}/Box/Insert`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  put(payload: BoxModel): Observable<boolean> {
    return this.http
      .put<boolean>(`${this.baseUrl}/Box/Update`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  delete(payload: BoxModel): Observable<boolean> {
    return this.http
      .delete<boolean>(`${this.baseUrl}/Box/Delete/${payload.id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetById(id: number): Observable<BoxModel> {
      return this.http
      .get<BoxModel>(`${this.baseUrl}/Box/GetById/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
     }
}
