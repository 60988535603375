import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { TranslateModule } from '@ngx-translate/core';


import { ComponentsModule } from '../../components/components.module';
import { HeaderModule } from '../../layouts/header/header.module';
import { FooterModule } from 'src/app/layouts/footer/footer.module';
import { SidebarModule } from 'src/app/layouts/sidebar/sidebar.module';

import { ProfileComponent } from './profile/profile.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { CompareComponent } from './compare/compare.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { ShippingAddressComponent } from './shipping-address/shipping-address.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AllPurchaseCouponsComponent } from './purchase-coupons/all-purchase-coupons/all-purchase-coupons.component';
import { CreatePurchaseCouponComponent } from './purchase-coupons/create-purchase-coupon/create-purchase-coupon.component';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angular2-qrcode';




@NgModule({
  declarations: [NgbCollapse ,
    ProfileComponent,
     WishlistComponent,
     CompareComponent,
      OrdersComponent,
       OrderDetailComponent,
        ShippingAddressComponent,
         AllPurchaseCouponsComponent ,
          CreatePurchaseCouponComponent
        ],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule,
    ComponentsModule,
    FormsModule,
    HeaderModule,
    FooterModule,
    QRCodeModule,
    SidebarModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [NgbCollapse , ProfileComponent, WishlistComponent, CompareComponent, OrdersComponent, OrderDetailComponent, ShippingAddressComponent, AllPurchaseCouponsComponent],
})
export class UserAccountModule { }
