import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UpToMessageModel } from './up-to-message.model';

@Injectable({
  providedIn: 'root'
})
export class UpToMessageService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }

  getMessage(): Observable<UpToMessageModel> {
    return this.http
      .get<UpToMessageModel>(`${this.baseUrl}/UpToMessage/GetMessage`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  update(model: UpToMessageModel): Observable<boolean> {
    return this.http
    .post<boolean>(`${this.baseUrl}/UpToMessage/Update`, model , this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error.json())));
   }
}
