import { GiftMessageDesignCategoryModel } from './models/gift-message-design-category.model';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GiftMessageDesignCategoryService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }

  getAll(page:number): Observable<GiftMessageDesignCategoryModel[]> {
 
    return this.http
      .get<GiftMessageDesignCategoryModel[]>(`${this.baseUrl}/GiftMessageDesignCategory/GetAll/${page}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCount(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/GiftMessageDesignCategory/GetCount`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  createGiftMessageDesignCategory(payload: GiftMessageDesignCategoryModel): Observable<boolean> {
    return this.http
      .post<boolean>(`${this.baseUrl}/GiftMessageDesignCategory/Insert`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  put(payload: GiftMessageDesignCategoryModel): Observable<boolean> {
    return this.http
      .put<boolean>(`${this.baseUrl}/GiftMessageDesignCategory/Update`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  delete(payload: GiftMessageDesignCategoryModel): Observable<boolean> {
    return this.http
      .delete<boolean>(`${this.baseUrl}/GiftMessageDesignCategory/Delete/${payload.id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  
  GetById(id: number): Observable<GiftMessageDesignCategoryModel> {
      return this.http
      .get<GiftMessageDesignCategoryModel>(`${this.baseUrl}/GiftMessageDesignCategory/GetById/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
     }

 

 
}
