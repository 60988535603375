import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ContactModel } from 'src/app/models/contact.model';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem('token');
   httpOptions = {
    headers: new HttpHeaders({
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {
  }
  GetContact(): Observable<ContactModel> {
    return this.http
      .get<ContactModel>(`${this.baseUrl}/ContactUs/GetContact`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

 put(payload: ContactModel): Observable<boolean> {
        return this.http
          .put<boolean>(`${this.baseUrl}/ContactUs/Update`, payload, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }

}
