import { ViewCiftInEmailComponent } from './../../../../components/view-cift-in-email/view-cift-in-email.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { AfterContentInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ExecutePaymentRequestModel } from 'src/app/pages/checkout/models/my-fatoorah/ExecutePaymentRequest.model';
import { PaymentMethodsModel } from 'src/app/pages/checkout/models/my-fatoorah/payment-methods.model';
import { MyFatoorahService } from 'src/app/pages/checkout/my-fatoorah.service';
import { KatoraService } from 'src/app/services/katora.service';
import { environment } from 'src/environments/environment';
import { GiftCardService } from '../../gift-card.service';
import { GiftMessageDesignCategoryService } from '../../gift-message-design-category.service';
import { GivingGiftService } from '../../giving-gift.service';
import { CarouselModel } from '../../models/carousel.model';
import { EmailModel } from '../../models/email.model';
import { GiftCardModel } from '../../models/gift-card.model';
import { GiftMessageDesignCategoryModel } from '../../models/gift-message-design-category.model';
import { GivingGiftModel } from '../../models/giving-gift.model';
import { emailValidator, phoneValidator } from 'src/app/core/utils/app-validators';
import { MatDialog, MatDialogRef } from '@angular/material';
declare var $: any;
@Component({
    selector: 'giving-gift-two',
    templateUrl: 'giving-gift-two.component.html',
    styleUrls: ['giving-gift-two.component.scss']
})
export class GivingGiftTwoComponent implements OnInit,AfterContentInit {
    @Output() public onUploadFinished = new EventEmitter();
    @Output() public onUploadVideo = new EventEmitter();

    @ViewChild('carousel', { static: false })  _carousel: ElementRef;

ngAfterContentInit() {
  //debugger;
  const myCarousel = document.querySelector('#carousel');
   const carousel = $(myCarousel).carousel();
}
    backgroundColer=''
    slideConfigTabFeatured = {
        slidesToShow: 5,
        slidesToScroll: 5,
        dots: true,
        infinite: false,
        arrows : false,
        responsive: [
          {
          breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
          breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
          breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    
      };
      slideConfigCarousel = {
        slidesToShow : 5,
        slidesToScroll: 5,
        dots: false,
        infinite: true,
        arrows : true,
        autoplay: true,
        autoplaySpeed: 100,
        speed: 100,
        pauseOnHover: true,
        pauseOnDotsHover: true,
        responsive: [
          {
          breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
          breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    
      };
    isEmail: 'false' | 'true' = 'true';
    GiftId = 0;
    step = 'step-amount';
    currentJustify = 'start';
    imageUrl = "";
    cardType = 'VISA';
    cardName = '';
    cardNumber = '';
    expirationMonth='';
    expirationYear='';
    securityCode = '';
    friendName  = '';
    friendEmail = '';
    friendPhone = '';
    gift : GiftCardModel = {};
    givingModel:GivingGiftModel = {};
    openCategories = false;
    category :GiftMessageDesignCategoryModel = {};
    categories : GiftMessageDesignCategoryModel[] = [];
    categoriesBackup : GiftMessageDesignCategoryModel[] = [];
    FirstThreecategories : GiftMessageDesignCategoryModel[] = [];
    cards : GiftCardModel []=[];
    imageOrVideoType = 0;
    FrindDataForm :FormGroup;
    ShowQR = false;
  public current_language='ar';
  emailModel : EmailModel = {};
    constructor(
        private giftService: GiftCardService,
        private route: ActivatedRoute,
        private designCategoryService: GiftMessageDesignCategoryService,
        private sanitization: DomSanitizer,
         private katoraService:KatoraService,
         private http: HttpClient,
        private givingGiftService : GivingGiftService,
        public toastr : ToastrService,
        private router:Router,
        private myFatoorahServeice:MyFatoorahService,
        private fb:FormBuilder,
    private matDialog : MatDialog,

      ) { 
        this.GiftId = + route.snapshot.paramMap.get('Id');
        this.givingModel = new GiftCardModel();
        this.current_language = localStorage.getItem('lang');
    }
      saveImage(item){
        return this.sanitization.bypassSecurityTrustStyle(`url(${item})`);
      }
      categoriesLength = false;
 paymentMethods : PaymentMethodsModel[]=[];

    ngOnInit(): void {
        this.giftService.GetById(this.GiftId)
        .subscribe(data => {
            this.gift = data;
        });
        this.designCategoryService.getAll(0)
        .subscribe(data => {
            this.categories = data;
            this.categoriesBackup = [...data];
            this.FirstThreecategories = data.splice(0, 3)
            this.openCategories = true;
            this.category = this.FirstThreecategories[0];
            this.chooseCategory(this.category);
            // this.category.images = this.categories[0].images;
            if(this.FirstThreecategories.length > 0){
              this.givingModel.designCategoryId =  this.FirstThreecategories[0].id;
            }
            if(this.categories.length > 0){
              this.categoriesLength = true;
            }
          //   this.givingModel.categoryImageUrl = this.category.imageUrl;
          //   this.givingModel.backGroundColor = this.category.backGroundColor;
          //   this.givingModel.amount = 0;
          //   this.images = this.category.images;
          //   this.category.images = this.images;//.splice(0,6);
          //   // this.givingModel.categoryImageUrl = this.category.images[0].imageUrl;
          //   var i = 1;
          //   while(this.images.length > 0){
          //     var item1=new CarouselModel();
          //     item1.id=i;
          //     item1.images = this.images.splice(0,6)
          //     i++;
          //     this.CarouselList.push(item1);
          // }
        });
        this.cards = this.katoraService.getProductGiftCards();
        this.FrindDataForm = this.createFrindDataForm();
    }
    createFrindDataForm():FormGroup{
      return this.fb.group({
        friendEmail:[null, Validators.compose([Validators.required, emailValidator])],
        friendName:[this.friendName,Validators.required],
        friendPhone:[null, Validators.compose([Validators.required, phoneValidator])],
      })
    }
    paymentInitialized = false;
    initializePayment(){
      //debugger;
      this.myFatoorahServeice.InitiatePayment(+ this.givingModel.amount)
      .subscribe(initiateResult => {
        this.step = 'payment-steps';
        this.paymentInitialized = true;
        this.paymentMethods = initiateResult.Data.PaymentMethods;
    
      })
     }
    chceck(){
    }
  getBoolean(value){
      switch(value){
           case true:
           case "true":
           case 1:
           case "1":
           case "on":
           case "yes":
               return true;
           default: 
               return false;
       }
      }
      ComplateAmountStep = false;
      chooseAmount(item){
         //debugger;
        this.givingModel.amount = item.amount
        this.category = item;
        this.ComplateAmountStep = true;
        this.step = 'personalize-message';
      }
      complateDesginStep = false;
      chooseImage(imageUrl,backGroundColor){
        //debugger;
        this.givingModel.categoryImageUrl = imageUrl;
          this.givingModel.backGroundColor = backGroundColor;
          this.step = 'deliver-steps';
        this.complateDesginStep = true;

      }
      setStep(item:string){
         
        this.step = item;
      }
      baseUrl = environment.DevApiUrl;
      DevImageUrl=environment.DevImageUrl;
      public response: {fileName: ''};
        public progress: number;
        public message: string;
        public uploadFile = (files) => {
          this.givingModel.imageOrVideoType = 1;
          this.givingModel.videoUrl = ''
          this.imageOrVideoType = 1 ;
          if (files.length === 0) {
            return;
          }
          let fileToUpload = <File>files[0];
          const formData = new FormData();
          formData.append('file', fileToUpload, fileToUpload.name);
          this.http.post(`${this.baseUrl}/GivingGift/UploadImage`, formData, {reportProgress: true, observe: 'events'})
            .subscribe(event => {
              if (event.type === HttpEventType.UploadProgress)
                this.progress = Math.round(100 * event.loaded / event.total);
              else if (event.type === HttpEventType.Response) {
                this.message = 'Upload success.';
                this.onUploadFinished.emit(event.body);
                this.uploadFinished(event.body);
              }
            });
        }
        public uploadFinished = (event) => {
          this.response = event;
          this.givingModel.imageUrl=`${this.DevImageUrl}/Resources/GivingCardImages/`+event;
        }
        deleteImage():void{
          this.givingModel.imageUrl='';
        }
        public uploadVideo = (files) => {
          this.givingModel.imageOrVideoType = 2;
          this.givingModel.imageUrl = ''
          this.imageOrVideoType = 2 ;

          if (files.length === 0) {
            return;
          }
          let fileToUpload = <File>files[0];
          const formData = new FormData();
          formData.append('file', fileToUpload, fileToUpload.name);
          this.http.post(`${this.baseUrl}/GivingGift/UploadVideo`, formData, {reportProgress: true, observe: 'events'})
            .subscribe(event => {
              if (event.type === HttpEventType.UploadProgress)
                this.progress = Math.round(100 * event.loaded / event.total);
              else if (event.type === HttpEventType.Response) {
                this.message = 'Upload success.';
                this.onUploadVideo.emit(event.body);
                this.uploadVideoFinished(event.body);
              }
            });
        }
        public uploadVideoFinished = (event) => {
          this.response = event;
          this.givingModel.videoUrl=`${this.DevImageUrl}/Resources/GivingCardVideos/`+event;
        }
        deleteVideo():void{
          this.givingModel.videoUrl='';
        }
        onPay(){
          debugger;
          this.givingModel.amount          = this.givingModel.amount;
          this.givingModel.qrurl          = this.givingModel.qrurl;
          this.givingModel.designCategoryId= this.givingModel.designCategoryId;
          this.givingModel.imageUrl        = this.givingModel.imageUrl;
          this.givingModel.backGroundColor        = this.givingModel.backGroundColor;
          this.givingModel.categoryImageUrl= this.givingModel.categoryImageUrl;
          this.givingModel.videoUrl        = this.givingModel.videoUrl;
          this.givingModel.imageOrVideoType= this.imageOrVideoType;
          this.givingModel.message         = this.givingModel.message;
          this.givingModel.friendName      = this.FrindDataForm.controls['friendName'].value ;
          this.givingModel.friendEmail     = this.FrindDataForm.controls['friendEmail'].value;
          this.givingModel.friendPhone     = this.FrindDataForm.controls['friendPhone'].value;
          this.givingModel.paymentType     = 0;
          this.givingModel.cardName        = this.cardName;
          this.givingModel.cardNumber      =this.cardNumber;
          this.givingModel.expirationMonth =this.expirationMonth;
          this.givingModel.expirationYear  =this.expirationYear;
          this.givingModel.cardType        =this.cardType;
          this.givingModel.securityCode    =this.securityCode;
          this.givingModel.deleveryDate    = new Date();
          this.givingModel.createDate    = new Date();
          this.givingModel.isEmail         = this.isEmail == 'true' ? true : false;
          this.givingModel.cards           =this.cards;
          this.givingModel.giftId           =this.GiftId;
          this.givingGiftService.createGivingGiftCard(this.givingModel)
          .subscribe(result => {
            if(result > 0 ){
              // this.toastr.success('The gift has been sent successfully');
              this.ExecutePayment(result);

            }
            else if(result == "Please Login" ){
              // this.toastr.success('The gift has been sent successfully');
              this.toastr.error('Please Login');
            }
            else{
              this.toastr.error('Please Try Agian');
            }
          });
        }
        images = [];
        openImages = false;
        CarouselList:CarouselModel[]=[];
        chooseCategory(item : GiftMessageDesignCategoryModel){
          debugger;
          this.step = 'deliver-steps';
          this.CarouselList = [];
          this.complateDesginStep = true;
          this.givingModel.designCategoryId = item.id
          this.category = this.categoriesBackup.find(x=>x.id == item.id);
          this.givingModel.categoryImageUrl = item.imageUrl;
          this.givingModel.backGroundColor = item.backGroundColor;
          // this.categories = this.categoriesBackup;
          // this.images = [...this.categories.find(i => i.id == item.id).images]
          this.images = this.category.images.slice();
          // this.category.images = this.images;//.splice(0,6);
          // this.givingModel.categoryImageUrl = item.images[0].imageUrl;
          // this.givingModel.backGroundColor = item.images[0].backGroundColor;
          var i = 0;
          var SImages = this.images;
          var openLop = true;
          while(this.images.length > 0){
            var item1=new CarouselModel();
            item1.images = SImages.splice(0,6);
            // SImages.splice(0,6);
            item1.id = i + 1;
            i++;
            this.CarouselList.push(item1);
            var len = 0;
            this.CarouselList.forEach(element => {
              len += element.images.length;
            });
            if(len >= this.images.length){
              openLop = false;
            }
        }
      
          this.openImages= true;
        }
        openMoreCategory = false;
        openMoreCategoryFunction(){
          this.openMoreCategory = true;
        }
        DeletePhoto(){
          this.givingModel.imageUrl = '';
          this.imageOrVideoType = 0;
        }
        DeleteVideo(){
          this.givingModel.videoUrl = '';
          this.imageOrVideoType = 0;
        }
        PaymentMethodId = 0;
        myFatoorahExecutePayment : ExecutePaymentRequestModel={};
        ExecutePayment(Id){
          debugger;
         this.myFatoorahExecutePayment = new ExecutePaymentRequestModel();
         this.myFatoorahExecutePayment.PaymentMethodId = this.PaymentMethodId;
         this.myFatoorahExecutePayment.CallBackUrl = environment.GiftCallBackUrl+ Id.toString();
         this.myFatoorahExecutePayment.ErrorUrl = environment.ErrorUrl;
         this.myFatoorahExecutePayment.CustomerEmail = this.givingModel.friendEmail// this.shippingForm.controls['email'].value;
         this.myFatoorahExecutePayment.CustomerMobile = this.givingModel.friendPhone;
         this.myFatoorahExecutePayment.CustomerName = this.givingModel.friendName;
         this.myFatoorahExecutePayment.InvoiceValue = +this.givingModel.amount;
         if(this.current_language == 'ar')
         {
           this.myFatoorahExecutePayment.Language = 'AR';
         }
         else{
           this.myFatoorahExecutePayment.Language = 'EN';
         }
         console.log(this.myFatoorahExecutePayment);

         this.myFatoorahServeice.MyFatoorahPay(this.myFatoorahExecutePayment)
         .subscribe(data => {
          console.log(data);
           if(data.IsSuccess){
             location.replace(data.Data.PaymentURL);
           }
         })
        }
        public trackItem (index: number, item: any) {
          return item.id;
        }
        QRURL ='';
        ViewMode=false;
        View() {
          this.ViewMode = true;

          let dialogRef : MatDialogRef<ViewCiftInEmailComponent>;
          dialogRef =this.matDialog.open(ViewCiftInEmailComponent);
          dialogRef.componentInstance.data = this.givingModel;
          dialogRef.componentInstance.gift = this.cards[0];
          dialogRef.afterClosed()
          .subscribe(x=>{
          this.ViewMode = false;
             
          })
        }
}
