import { GivingGiftModel } from './../../models/giving-gift.model';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { KatoraService } from 'src/app/services/katora.service';
import { GivingGiftService } from '../../giving-gift.service';
import { GiftMessageDesignMode } from '../../models/gift-message-design.model';

@Component({
    selector: 'view-gift-card',
    templateUrl: 'view-gift-card.component.html',
    styleUrls: ['view-gift-card.component.scss']
})
export class ViewGiftCardComponent implements OnInit {
    GiftId = 0;
    givingModel: GiftMessageDesignMode = {};
    openForm = false;
    constructor(
        private route: ActivatedRoute,
        private sanitization: DomSanitizer,
         private katoraService:KatoraService,
         private http: HttpClient,
        private givingGiftService : GivingGiftService,
        public toastr : ToastrService,
        private router:Router
      ) { 
        this.GiftId = + route.snapshot.paramMap.get('Id');
      }
    ngOnInit(): void {
        this.givingGiftService.GetById(this.GiftId)
        .subscribe(data => {
            this.givingModel = data;
            console.log(this.givingModel);
            this.openForm = true;
        });
    }
    saveImage(item){
        return this.sanitization.bypassSecurityTrustStyle(`url(${item})`);
      }
      onApply(){
        const token = localStorage.getItem('token');
        if (token != null) {
          this.givingGiftService.Apply(this.GiftId)
          .subscribe(data => {
              if(data == "Apply Done Success"){
                  this.toastr.success(data);
                  this.ngOnInit();
              }
              else{
                  this.toastr.warning(data);
                  this.router.navigate(['login'])
              }
          });
        }
        else{
            this.toastr.error("Please Login To Apply Your Gift");
        }
      }
}
