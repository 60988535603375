import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CouponCodeModel } from '../models/coupon-code.model';

@Injectable({
  providedIn: 'root'
})
export class CouponCodeService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem('token');
   httpOptions = {
    headers: new HttpHeaders({
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {
  }
  GetByCode(code: string): Observable<CouponCodeModel> {
        return this.http
          .get<CouponCodeModel>(`${this.baseUrl}/CouponCode/GetByCode/${code}`)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
 used(payload: CouponCodeModel): Observable<boolean> {
        return this.http
          .get<boolean>(`${this.baseUrl}/CouponCode/Used/${payload.id}`, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
}
