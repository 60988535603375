import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CityService } from 'src/app/pages/session/city.service';
import { CityModel } from 'src/app/pages/session/model/city.model';

@Component({
    selector: 'slect-city',
    templateUrl: 'slect-city.component.html',
    styleUrls: ['slect-city.component.scss']
})
export class SlectCityComponent  implements OnInit {
    confirmForm: FormGroup;
    openForm = false;
    userId = 0;
    cities:CityModel[]=[];
    constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router ,
      public dialogRef : MatDialogRef<SlectCityComponent>,
      private cityService:CityService
      ){
        //   this.userId = + this.route.snapshot.paramMap.get('Id');
      }
      ngOnInit(): void {
          this.cityService.getAll(0)
          .subscribe(data => {
              this.cities = data;
              this.confirmForm = this.formBuilder.group({
                cityId : [0 , Validators.compose([Validators.required,Validators.pattern('^[1-9][0-9]*$')]) ]
              });
              this.openForm = true;
          })
   
      }
      submit() {
        
          const formData = this.confirmForm.getRawValue();
          this.dialogRef.close(formData);
      }
  
  }
