import { ProductModel } from 'src/app/pages/shop/models/Product.model';
import { ProductService } from './../../../shop/product.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ContactModel } from 'src/app/models/contact.model';
import { ContactService } from 'src/app/pages/user-account/services/contact.service';
import { CorporateGiftService } from '../../corporategift.service';
import { CorporateGiftModel } from '../../models/corporategift.model';
import { CorporateGiftProductsModel } from '../../models/corporate-gift-products.model';

@Component({
    selector: 'corporate-gift',
    templateUrl: 'corporate-gift.component.html',
    styleUrls: ['corporate-gift.component.scss']
})
export class CorporateGiftComponent implements  OnInit {
    message: CorporateGiftModel = {};
    Form: FormGroup;
  Contact: ContactModel = {};
  products:any[]=[];
  productId = 0;
  current_language='ar';
  selectedProduct:CorporateGiftProductsModel[]=[];
    constructor(
       private messageService: CorporateGiftService, 
       private fb: FormBuilder, 
    private contactService: ContactService,
    public toaster: ToastrService,
    private productService:ProductService,
       ) { 
    this.current_language = localStorage.getItem('lang');

       }
    createForm(): FormGroup{
      return this.fb.group({
        id: [this.message.id],
        email: [this.message.email, Validators.required],
        isView: [this.message.isView],
        mobile: [this.message.mobile, Validators.required],
        name: [this.message.name, Validators.required],
        phone: [this.message.phone],
        description: [this.message.description],
        companyName: [this.message.companyName, Validators.required],
      });
    }
    ngOnInit(): void {
      this.selectedProduct = [];
      this.contactService.GetContact()
      .subscribe(data => {
        this.Contact = data;
        this.productService.GetAllForCorpateGift()
        .subscribe(products=>{
          this.products = products;
          console.log(this.products);
          this.message = new CorporateGiftModel();
          this.Form = this.createForm();
        })
  
      });
    
    }
    SendMessage(): void{
      this.message = this.Form.getRawValue();
      this.message.products = this.selectedProduct;
      this.messageService.CreateCorporateGift(this.message)
      .subscribe(data => {
        if(data === true) {
          this.toaster.success('Add Done Successfly');
          this.message = new CorporateGiftModel();
      this.selectedProduct = [];
      this.Form = this.createForm();
        }
      });
    }
    chooseProduct(){
      debugger;
      this.productId = +this.productId;
        var i = this.products.findIndex(x=>x.id == this.productId);
        if(i > -1){
          var selected = new CorporateGiftProductsModel();
          selected.productId = this.products[i].id;
          selected.productImage = this.products[i].imageUrl;
          selected.productNameAr = this.products[i].nameAr;
          selected.productNameEn = this.products[i].nameEn;
          this.selectedProduct.push(selected);
          this.productId = 0;
        }
    }
    removeProduct(data){
      var i = this.selectedProduct.indexOf(data);
      if(i > -1){
        this.selectedProduct.splice(i,1);
      }
    }
    addQuantity(product: any){
      const i = this.selectedProduct.indexOf(product);
      if (i !== -1) {
        this.selectedProduct[i].quantity ++;
      }
  
    }
    removeQuantity(product: any){
      const i = this.selectedProduct.indexOf(product);
      if (i !== -1) {
        this.selectedProduct[i].quantity --;
      }
    }
  
  }
  