import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'complete-falsehood',
    templateUrl: 'complete-falsehood.component.html',
    styleUrls: ['complete-falsehood.component.scss']
})
export class CompleteFalsehoodComponent implements OnInit {
    constructor(
        public dialogRef : MatDialogRef<CompleteFalsehoodComponent>

    ) { }
    ngOnInit() {
    }
    accept(value:string) {
        this.dialogRef.close(value);
    }
  }
  