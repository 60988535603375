import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { observable, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MessageModel } from 'src/app/models/message.model';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem('token');
   httpOptions = {
    headers: new HttpHeaders({
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {
  }
  GetAll(page: number): Observable<MessageModel> {
    return this.http
      .get<MessageModel>(`${this.baseUrl}/Message/GetAll/${page}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetById(id: number): Observable<MessageModel> {
    return this.http
      .get<MessageModel>(`${this.baseUrl}/Message/GetById/${id}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  } 
  GetCount(): Observable<MessageModel> {
    return this.http
      .get<MessageModel>(`${this.baseUrl}/Message/GetCount`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  CreateMessage(payload: MessageModel): Observable<boolean>{
      return this.http
      .post<boolean>(`${this.baseUrl}/Message/Insert`, payload ,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  View(payload: MessageModel): Observable<boolean> {
        return this.http
          .put<boolean>(`${this.baseUrl}/Message/View`, payload, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
  Delete(id: number): Observable<boolean> {
        return this.http
          .delete<boolean>(`${this.baseUrl}/Message/Delete/${id}`, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
}
