import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {

  current_language = 'ar'
  ngOnInit() {
    this.current_language = localStorage.getItem('lang');

  }

}
